import {connect} from 'react-redux'
import AnswerKey from '../components/AnswerKey'
import {AppDispatch, RootState} from '../../common/redux/store'
import NavigationService from "../../common/service/NavigationService";

const mapStateToProps = (state: RootState) => ({
  questionList: state.result.questionList,
})

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  navigateToScoreCard() {
    NavigationService.navigateToScoreCard()
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(AnswerKey)
