import {connect} from 'react-redux'
import HeaderNavBar from "../components/HeaderNavBar";
import {AppDispatch, RootState} from "../redux/store";
import NavigationService from "../service/NavigationService";
import {logoutUser} from "../../authentication/common/redux/thunks";

const mapStateToProps = (state: RootState) => ({
    location: state.common.location
})

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    logoutUser: () => {
        dispatch(logoutUser())
    },

    navigateToDashboard: () => {
        NavigationService.navigateToDashboard()
    },

    navigateToSelection: () => {
        NavigationService.navigateToSelection()
    },

    navigateToProfile: () => {
        NavigationService.navigateToProfile()
    },
})

export default connect(mapStateToProps, mapDispatchToProps)(HeaderNavBar)
