import User from '../../common/model/User'
import userList from '../../common/data/prototype/userList'

interface InitialState {
  user: User
  modifiedUser: User
}

const initialState: InitialState = {
  user: userList[0],
  modifiedUser: userList[0],
}

export default initialState
