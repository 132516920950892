import React from 'react'
import {Alignment, Button, Card, Classes, Navbar, NavbarDivider, NavbarGroup, NavbarHeading} from '@blueprintjs/core'
import ThemeConstants from '../data/styles/ThemeConstants'
import HeaderNavBarItem from './HeaderNavBarItem'
import {isDesktop} from "react-device-detect";

interface Props {
    location: string
    logoutUser: () => void
    navigateToDashboard: () => void
    navigateToSelection: () => void
    navigateToProfile: () => void
}

export default function HeaderNavBar(props: Props) {
    if (isDesktop) {
        return (
            <Navbar>
                <NavbarGroup align={Alignment.LEFT}>
                    <NavbarHeading style={{fontFamily: ThemeConstants.headingFont}}>Brain Brush</NavbarHeading>
                    <NavbarDivider/>
                    <HeaderNavBarItem
                        url={'dashboard'}
                        icon={'dashboard'}
                        text={'Dashboard'}
                        currentLocation={props.location}
                        onClick={props.navigateToDashboard}
                    />
                    <HeaderNavBarItem
                        url={'selection'}
                        icon={'properties'}
                        text={'Test'}
                        currentLocation={props.location}
                        onClick={props.navigateToSelection}
                    />
                    <HeaderNavBarItem
                        url={'profile'}
                        icon={'user'}
                        text={'Profile'}
                        currentLocation={props.location}
                        onClick={props.navigateToProfile}
                    />
                </NavbarGroup>
                <NavbarGroup align={Alignment.RIGHT} style={{display: 'flex', alignItems: 'center'}}>
                    <Button
                        className={Classes.MINIMAL}
                        style={styles.button}
                        icon="log-out"
                        text="Logout"
                        onClick={props.logoutUser}
                    />
                </NavbarGroup>
            </Navbar>
        )
    } else {
        return <Card compact style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', paddingTop: 20}}>
            <HeaderNavBarItem
                url={'dashboard'}
                icon={'dashboard'}
                text={'Dashboard'}
                currentLocation={props.location}
                onClick={props.navigateToDashboard}
            />
            <HeaderNavBarItem
                url={'selection'}
                icon={'properties'}
                text={'Test'}
                currentLocation={props.location}
                onClick={props.navigateToSelection}
            />
            <HeaderNavBarItem
                url={'profile'}
                icon={'user'}
                text={'Profile'}
                currentLocation={props.location}
                onClick={props.navigateToProfile}
            />
            <HeaderNavBarItem
                url={'login'}
                icon={'log-out'}
                text={'Logout'}
                currentLocation={props.location}
                onClick={props.logoutUser}
            />
        </Card>
    }
}

const styles = {
    button: {
        fontFamily: ThemeConstants.headingFont,
    },
}
