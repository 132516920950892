import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import initialState from './initialState'

const Slice = createSlice({
    name: 'Common',
    initialState,
    reducers: {
        changeLocation(state, action: PayloadAction<string>) {
            state.location = action.payload
        },
        changeLoading(state, action: PayloadAction<boolean>) {
            state.loading = action.payload
        },
    },
})

export default Slice.reducer

export const {changeLocation, changeLoading} = Slice.actions
