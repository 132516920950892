export class MCQ {
    question: string = ''
    choices: string[] = []
    supportImage: string = ''
    imageChoice: boolean = false

    static isValidAnswer(mcq: MCQ, answer: string): boolean {
        return mcq.choices.includes(answer)
    }
}

export class AssertionReason {
    assertion: string = ''
    reason: string = ''

    static validAnswers = [
        'Both assertion and reason are false',
        'Assertion is true but reason is false',
        'Assertion dasdasdasdasdasdasda',
        'Assertion is n oaihsd ash pashd oasid',
    ]

    static isValidAnswer(assertionReason: AssertionReason, answer: string): boolean {
        return this.validAnswers.includes(answer)
    }
}

export class Match {
    leftColumn: string[] = []
    rightColumn: string[] = []
}

type QuestionContent = MCQ | AssertionReason | Match

export default QuestionContent
