import Controller from "../../common/service/Controller";
import CompletionStatus from "../../common/model/CompletionStatus";

class CompletionStatusService {

    async getCompletionStatusData(): Promise<CompletionStatus[]> {
        return Controller.fetch('/user/completion', {})
    }
}

const completionStatusService: CompletionStatusService = new CompletionStatusService()

export default completionStatusService
