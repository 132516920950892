import {connect} from 'react-redux'
import ScoreCard from '../components/ScoreCard'
import {AppDispatch, RootState} from '../../common/redux/store'
import NavigationService from "../../common/service/NavigationService";

const mapStateToProps = (state: RootState) => ({
    score: state.result.score.score,
    correctCount: state.result.score.correctCount,
    incorrectCount: state.result.score.incorrectCount,
    unansweredCount: state.result.score.unansweredCount,
    timeTaken: state.result.testData ? state.result.testData.timeTaken : -1,
})

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    navigateToAnswerKey() {
        NavigationService.navigateToAnswerKey()
    }
})

export default connect(mapStateToProps, mapDispatchToProps)(ScoreCard)
