import QuestionContent, {AssertionReason, MCQ} from './QuestionContent'
import QuestionType from './QuestionType'
import React from "react";
// @ts-ignore
import MathJax from 'react-mathjax2'
import Module from './Module'

export default class Question {
    id: string = '-1'
    content: QuestionContent = new MCQ()
    answer: string = ''
    typeId: QuestionType = 'MCQ'
    module: Module = {id: '-1', name: ''}

    static formatQuestion(question: Question): Question {
        if (question.typeId === 'MCQ' || 1) {
            return {
                ...question,
                content: {
                    ...question.content,
                    choices: (question.content as MCQ).choices
                        .map((a) => ({sort: Math.random(), value: a}))
                        .sort((a, b) => a.sort - b.sort)
                        .map((a) => a.value),
                },
            }
        } else {
            return question
        }
    }

    static formatText(fullText: string) {
        return fullText.split('<br>').map((text) => (
            <MathJax.Context>
                <MathJax.Text text={text} />
            </MathJax.Context>
        ))
    }

    static changeQuestionType(question: Question, questionType: QuestionType): Question {
        return {
            ...question,
            typeId: questionType,
            content: Question.getQuestionContent(questionType),
            answer: '',
        }
    }

    static getQuestionContent(questionType: QuestionType): QuestionContent {
        switch (questionType) {
            case 'MCQ':
                return new MCQ()
            case 'AR':
                return new AssertionReason()
        }
    }

    static setAnswer(question: Question, answer: string): Question {
        if (question.typeId == 'MCQ') {
            if (MCQ.isValidAnswer(question.content as MCQ, answer)) {
                return {...question, answer}
            }
        } else if (question.typeId === 'AR') {
            if (AssertionReason.isValidAnswer(question.content as AssertionReason, answer)) {
                return {...question, answer}
            }
        }
        return question
    }
}
