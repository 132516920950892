import React from 'react'
import AnswerBlock from './AnswerBlock'
import QuestionBlock from './QuestionBlock'
import SupportImageBlock from './SupportImageBlock'
import ThemeConstants from '../../../common/data/styles/ThemeConstants'
import {QuestionKey} from '../../../common/model/QuestionKey'
import {Button, H2, H3} from '@blueprintjs/core'
import ButtonStyle from '../../../common/data/styles/ButtonStyle'
import {Link} from 'react-router-dom'
import ScreenWithHeader from "../../../common/components/ScreenWithHeader";

interface AnswerKeyProps {
    questionList: QuestionKey[]
    navigateToScoreCard: () => void
}

export default function AnswerKey(props: AnswerKeyProps) {
    return (
        <ScreenWithHeader>
            <div style={styles.container}>
                <H3 style={styles.pageHeading}>Answer Key</H3>
                <Button text={'Summary'} style={{marginTop: '2vh', marginRight: '4vw'}} outlined rightIcon={'chart'} onClick={props.navigateToScoreCard} />
            </div>
            <>
                <div // TODO: Check what this is
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                    }}
                />
                <div style={styles.questionList}>
                    {props.questionList.map((question, index) => (
                        <div style={styles.questionReviewContainer}>
                            <QuestionBlock question={question.question} index={index} />
                            <SupportImageBlock supportImage={question.supportImage} />
                            <div style={styles.answerBlock}>
                                <AnswerBlock answer={question.answer} text={'Correct'} />
                                {question.selectedAnswer && question.selectedAnswer.length ? (
                                    <AnswerBlock answer={question.selectedAnswer} text={'Your'} />
                                ) : (
                                    <div />
                                )}
                            </div>
                        </div>
                    ))}
                </div>
            </>
        </ScreenWithHeader>
    )
}

const styles = {
    page: {
        display: 'flex',
        flexDirection: 'column' as 'column',
        flex: 1,
    },
    pageHeading: {
        marginLeft: '4vw',
        marginTop: '3vh',
    },
    container: {
        display: 'flex',
        flexDirection: 'row' as 'row',
        justifyContent: 'space-between',
    },
    heading: {
        fontSize: '4em',
        marginLeft: '0.8em',
        marginRight: '0.4em',
        marginTop: '0.4em',
        marginBottom: '0.4em',
        width: 'fit-content',
        fontWeight: 400,
        // color: ThemeConstants.secondaryColor,
        fontFamily: ThemeConstants.headingFont,
    },
    questionReviewContainer: {
        display: 'flex',
        flexDirection: 'column' as 'column',
    },
    answerBlock: {
        display: 'flex',
        flexDirection: 'column' as 'column',
    },
    questionList: {
        padding: '3vw',
        paddingTop: 0,
    },
}
