import Controller from '../../common/service/Controller'
import SelectionFilter from "../../common/model/SelectionFilter";
import RawTest from "../../common/model/RawTest";
import Test, {TestManipulator} from "../../common/model/Test";
import Score from "../../common/model/Score";
import TestResult from "../../common/model/TestResult";
import {MCQ} from "../../common/model/QuestionContent";

class TestService {

    async fetchTest(selectionFilter: SelectionFilter): Promise<Test> {
        console.log(JSON.stringify(selectionFilter))
        const rawTest: RawTest = await Controller.fetch('/test/get', { selectionFilter })
        console.log(rawTest)
        rawTest.questionList.forEach((question, index) => {
            (rawTest.questionList[index].content as MCQ).choices = TestService.shuffleArray((rawTest.questionList[index].content as MCQ).choices)
        })
        return TestManipulator.createNewTestInstance(rawTest)
    }

    static shuffleArray(originalArray: any[]): any[] {
        let array = [...originalArray];
        for (let i = array.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [array[i], array[j]] = [array[j], array[i]];
        }
        return array;
    }

    async saveTest(testData: Test, selectionFilter: SelectionFilter): Promise<void> {
        let test: Test = {...testData, questionInstanceList: [...testData.questionInstanceList]}
        for (let i: number = 0; i < testData.questionList.length; i++) {
            if (testData.questionList[i].answer === testData.questionInstanceList[i].selectedAnswer) {
                test.questionInstanceList[i] = {
                    ...testData.questionInstanceList[i], correct: true
                }
            }
        }
        test.score = Score.fromQuestionInstanceList(test.questionInstanceList)
        const testResult: TestResult = TestManipulator.transformToResult(test, selectionFilter)
        await Controller.fetchVoid('/test/save', { test: testResult })
        console.log("saving", testResult)
    }
}

const testService: TestService = new TestService()

export default testService
