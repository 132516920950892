import {combineReducers} from '@reduxjs/toolkit'
import CommonReducer from './slice'
import AuthenticationReducer from '../../authentication/common/redux/slice'
import DashboardReducer from '../../dashboard/redux/slice'
import SelectionReducer from '../../selection/redux/slice'
import TestReducer from '../../test/redux/slice'
import ResultReducer from '../../result/redux/slice'
import ProfileReducer from '../../profile/redux/slice'

const rootReducer = combineReducers({
  authentication: AuthenticationReducer,
  dashboard: DashboardReducer,
  selection: SelectionReducer,
  test: TestReducer,
  result: ResultReducer,
  common: CommonReducer,
  profile: ProfileReducer,
})

export type RootState = ReturnType<typeof rootReducer>

export default rootReducer
