import QuestionInstance from './QuestionInstance'
import Question from './Question'
import RawTest from './RawTest'
import TestResult from './TestResult'
import questionList from '../data/prototype/questionList'
import Score from "./Score";
import SelectionFilter from "./SelectionFilter";
import {MCQ} from "./QuestionContent";

export default interface Test {
    id: string
    score: Score
    timeTaken: number
    questionList: Question[]
    questionInstanceList: QuestionInstance[]
    timestamp: number
}

export class TestManipulator {

    static createNewTestInstance(rawTest: RawTest): Test {
        const questionInstanceList: QuestionInstance[] = rawTest.questionList.map(
            (question: Question): QuestionInstance => ({
                questionId: question.id,
                correct: false,
                timeTaken: 0,
                selectedAnswer: '',
            }),
        )
        return {
            id: rawTest.id,
            score: new Score(),
            timeTaken: 0,
            timestamp: 0,
            questionList: rawTest.questionList.map(question => ({
                ...question,  content: {
                    ...question.content, choices: this.shuffleArray((question.content as MCQ).choices)
                }
            })),
            questionInstanceList,
        }
    }

    static shuffleArray(originalArray: any[]): any[] {
        let array = [...originalArray];
        for (let i = array.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [array[i], array[j]] = [array[j], array[i]];
        }
        return array;
    }

    static transformToResult = (test: Test, selectionFilter: SelectionFilter): TestResult => ({
        id: test.id,
        score: test.score,
        timeTaken: test.questionInstanceList
            .map((questionInstance: QuestionInstance): number => questionInstance.timeTaken)
            .reduce((acc: number, curr: number) => acc + curr),
        questionInstanceList: test.questionInstanceList,
        timestamp: test.timestamp,
        testType: selectionFilter.testType,
        module: selectionFilter.module,
        set: selectionFilter.setNumber
    })

    static getEmptyInstance = (): Test => ({
        id: '0',
        score: new Score(),
        timeTaken: 0,
        questionList: [],
        questionInstanceList: [],
        timestamp: 0,
    })

    static getMockInstance = (): Test => ({
        id: '-1',
        score: new Score(),
        timeTaken: 0,
        questionList: questionList,
        questionInstanceList: questionList.map((question: Question) => ({
            questionId: question.id,
            timeTaken: 0,
            selectedAnswer: '',
            correct: false,
        })),
        timestamp: 0,
    })
}
