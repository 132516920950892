import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import initialState from './initialState'
import LogEntry from '../../common/model/LogEntry'
import CompletionStatus from '../../common/model/CompletionStatus'
import User from "../../common/model/User";

const Slice = createSlice({
  name: 'Dashboard',
  initialState,
  reducers: {
    changeLogEntries(state, action: PayloadAction<LogEntry[]>) {
      state.logEntries = action.payload
    },

    changeLeaderboardEntries(state, action: PayloadAction<User[]>) {
      state.leaderboardEntries = action.payload
    },

    changeCompletionData(state, action: PayloadAction<CompletionStatus[]>) {
      state.completionData = action.payload
    },
  },
})

export default Slice.reducer

export const {
    changeLogEntries,
    changeLeaderboardEntries,
    changeCompletionData,
} = Slice.actions
