import {connect} from 'react-redux'
import {RootState} from './common/redux/store'
import App from './App'

const mapStateToProps = (state: RootState) => ({
  location: state.common.location,
  loading: state.common.loading,
})

const mapDispatchToProps = () => ({})

export default connect(mapStateToProps, mapDispatchToProps)(App)
