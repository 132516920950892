import Module from '../../model/Module'

const moduleList: Module[] = [
  {id: '1', name: 'Module #1'},
  {id: '1', name: 'Module #2'},
  {id: '1', name: 'Module #3'},
  {id: '1', name: 'Module #4'},
  {id: '1', name: 'Module #5'},
  {id: '1', name: 'Module #6'},
  {id: '1', name: 'Module #7'},
  {id: '1', name: 'Module #8'},
  {id: '1', name: 'Module #9'},
]

export default moduleList
