import CompletionStatus, {completionStatusList} from '../../common/model/CompletionStatus'
import User from "../../common/model/User";
import userList from "../../common/data/prototype/userList";
import LogEntry from "../../common/model/LogEntry";

interface InitialState {
    logEntries: LogEntry[]
    leaderboardEntries: User[]
    completionData: CompletionStatus[]
}

const initialState: InitialState = {
    logEntries: [
        {
            timestamp: new Date().getTime(),
            timeTaken: 1200,
            totalQuestions: 100,
            correctQuestionCount: 90,
            module: 'Foreign Exchange',
            setNumber: 100,
            testType: 'MODULE',
        },
        {
            timestamp: new Date().getTime(),
            timeTaken: 1200,
            totalQuestions: 100,
            correctQuestionCount: 90,
            module: 'Foreign Exchange',
            setNumber: 100,
            testType: 'MODULE',
        },
        {
            timestamp: new Date().getTime(),
            timeTaken: 1200,
            totalQuestions: 100,
            correctQuestionCount: 90,
            module: 'Foreign Exchange',
            setNumber: 100,
            testType: 'MODULE',
        },
        {
            timestamp: new Date().getTime(),
            timeTaken: 1200,
            totalQuestions: 100,
            correctQuestionCount: 90,
            module: 'Foreign Exchange',
            setNumber: 100,
            testType: 'MODULE',
        },
        {
            timestamp: new Date().getTime(),
            timeTaken: 1200,
            totalQuestions: 100,
            correctQuestionCount: 90,
            module: 'Foreign Exchange',
            setNumber: 100,
            testType: 'MODULE',
        },
        {
            timestamp: new Date().getTime(),
            timeTaken: 1200,
            totalQuestions: 100,
            correctQuestionCount: 90,
            module: 'Foreign Exchange',
            setNumber: 100,
            testType: 'MODULE',
        },
        {
            timestamp: new Date().getTime(),
            timeTaken: 1200,
            totalQuestions: 100,
            correctQuestionCount: 90,
            module: 'Foreign Exchange',
            setNumber: 100,
            testType: 'MODULE',
        },
        {
            timestamp: new Date().getTime(),
            timeTaken: 1200,
            totalQuestions: 100,
            correctQuestionCount: 90,
            module: 'Foreign Exchange',
            setNumber: 100,
            testType: 'MODULE',
        },
        {
            timestamp: new Date().getTime(),
            timeTaken: 1200,
            totalQuestions: 100,
            correctQuestionCount: 90,
            module: 'Foreign Exchange',
            setNumber: 100,
            testType: 'MODULE',
        },
        {
            timestamp: new Date().getTime(),
            timeTaken: 1200,
            totalQuestions: 100,
            correctQuestionCount: 90,
            module: 'Foreign Exchange',
            setNumber: 100,
            testType: 'MODULE',
        },
        {
            timestamp: new Date().getTime(),
            timeTaken: 1200,
            totalQuestions: 100,
            correctQuestionCount: 90,
            module: 'Foreign Exchange',
            setNumber: 100,
            testType: 'MODULE',
        },
        {
            timestamp: new Date().getTime(),
            timeTaken: 1200,
            totalQuestions: 100,
            correctQuestionCount: 90,
            module: 'Foreign Exchange',
            setNumber: 100,
            testType: 'MODULE',
        },
        {
            timestamp: new Date().getTime(),
            timeTaken: 1200,
            totalQuestions: 100,
            correctQuestionCount: 90,
            module: 'Foreign Exchange',
            setNumber: 100,
            testType: 'MODULE',
        },
        {
            timestamp: new Date().getTime(),
            timeTaken: 1200,
            totalQuestions: 100,
            correctQuestionCount: 90,
            module: 'Foreign Exchange',
            setNumber: 100,
            testType: 'MODULE',
        },
        {
            timestamp: new Date().getTime(),
            timeTaken: 1200,
            totalQuestions: 100,
            correctQuestionCount: 90,
            module: 'Foreign Exchange',
            setNumber: 100,
            testType: 'MODULE',
        },
        {
            timestamp: new Date().getTime(),
            timeTaken: 1200,
            totalQuestions: 100,
            correctQuestionCount: 90,
            module: 'Foreign Exchange',
            setNumber: 100,
            testType: 'MODULE',
        },
        {
            timestamp: new Date().getTime(),
            timeTaken: 1200,
            totalQuestions: 100,
            correctQuestionCount: 90,
            module: 'Foreign Exchange',
            setNumber: 100,
            testType: 'MODULE',
        },
        {
            timestamp: new Date().getTime(),
            timeTaken: 1200,
            totalQuestions: 100,
            correctQuestionCount: 90,
            module: 'Foreign Exchange',
            setNumber: 100,
            testType: 'MODULE',
        },
        {
            timestamp: new Date().getTime(),
            timeTaken: 1200,
            totalQuestions: 100,
            correctQuestionCount: 90,
            module: 'Foreign Exchange',
            setNumber: 100,
            testType: 'MODULE',
        },
        {
            timestamp: new Date().getTime(),
            timeTaken: 1200,
            totalQuestions: 100,
            correctQuestionCount: 90,
            module: 'Foreign Exchange',
            setNumber: 100,
            testType: 'MODULE',
        },
        {
            timestamp: new Date().getTime(),
            timeTaken: 1200,
            totalQuestions: 100,
            correctQuestionCount: 90,
            module: 'Foreign Exchange',
            setNumber: 100,
            testType: 'MODULE',
        },
        {
            timestamp: new Date().getTime(),
            timeTaken: 1200,
            totalQuestions: 100,
            correctQuestionCount: 90,
            module: 'Foreign Exchange',
            setNumber: 100,
            testType: 'MODULE',
        },
        {
            timestamp: new Date().getTime(),
            timeTaken: 1200,
            totalQuestions: 100,
            correctQuestionCount: 90,
            module: 'Foreign Exchange',
            setNumber: 100,
            testType: 'MODULE',
        },
    ],
    leaderboardEntries: userList,
    completionData: completionStatusList,
}

export default initialState
