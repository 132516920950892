export default interface CompletionStatus {
    name: string
    totalSets: number
    completedSets: number
    moduleTestCount: number
}

export const completionStatusList: CompletionStatus[] = [
    {
        name: 'Ilakkanam',
        totalSets: 100,
        completedSets: 87,
        moduleTestCount: 2,
    },
    {
        name: 'Ilakkiam',
        totalSets: 100,
        completedSets: 87,
        moduleTestCount: 2,
    },
    {
        name: 'General Knowledge',
        totalSets: 100,
        completedSets: 87,
        moduleTestCount: 2,
    },
    {
        name: 'History',
        totalSets: 100,
        completedSets: 87,
        moduleTestCount: 2,
    },
    {
        name: 'Geography',
        totalSets: 100,
        completedSets: 87,
        moduleTestCount: 2,
    },
    {
        name: 'Constitution',
        totalSets: 100,
        completedSets: 87,
        moduleTestCount: 2,
    },
    {
        name: 'Science',
        totalSets: 100,
        completedSets: 87,
        moduleTestCount: 2,
    },
    {
        name: 'VAO',
        totalSets: 100,
        completedSets: 87,
        moduleTestCount: 2,
    },
]
