import React from 'react'
import HeaderNavBarContainer from "../containers/HeaderNavBarContainer";

interface Props {
    children?: React.ReactNode
}

export default function ScreenWithHeader(props: Props) {
    return (
        <div style={styles.page}>
            <HeaderNavBarContainer />
            {props.children}
        </div>
    )
}

const styles = {
    page: {
        height: '100%',
        width: '100%',
    }
}
