import {connect} from 'react-redux'
import Selection from '../components/Selection'
import {AppDispatch, RootState} from '../../common/redux/store'
import {TestType} from '../../common/model/SelectionFilter'
import {changeTestType} from '../redux/slice'
import {startTest} from "../../test/redux/thunks";

const mapStateToProps = (state: RootState) => ({
    testType: state.selection.testType,
})

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    changeTestType(testType: TestType) {
        dispatch(changeTestType(testType))
    },

    startTest() {
        dispatch(startTest())
    },
})

export default connect(mapStateToProps, mapDispatchToProps)(Selection)
