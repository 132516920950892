import React from 'react'
import {Button, H3, Intent} from '@blueprintjs/core'
import ButtonStyle from '../../common/data/styles/ButtonStyle'
import Module from '../../common/model/Module'

interface ModuleTestSelectionProps {
  moduleList: Module[]
  module: Module | null
  changeModule: (module: Module) => void
}

export default function ModuleTestSelection(props: ModuleTestSelectionProps) {
  return (
    <div style={styles.container}>
      <H3 style={styles.heading}>Select Module</H3>
      {props.moduleList.map((module) => (
        <Button
          text={module.name}
          minimal
          onClick={() => {
            props.changeModule(module)
          }}
          style={styles.button}
          icon={props.module === module ? 'tick-circle' : undefined}
          intent={props.module === module ? Intent.PRIMARY : Intent.NONE}
          alignText={'left'}
        />
      ))}
    </div>
  )
}

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column' as 'column',
    alignSelf: 'flex-start',
    backgroundColor: 'white',
    margin: '1em',
    marginTop: '0',
    width: '33%',
    height: '100%',
    overflowY: 'auto' as 'auto',
  },
  heading: {
    paddingLeft: '1em',
    marginBottom: '0',
    marginTop: '1em',
  },
  button: {
    ...ButtonStyle,
    marginBottom: '0',
    marginLeft: '0.5em',
    paddingLeft: '1em',
  },
}
