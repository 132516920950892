import React from 'react'
import ThemeConstants from '../../../common/data/styles/ThemeConstants'
import LogEntry from "../../../common/model/LogEntry";

interface LogEntryProps {
    logEntry: LogEntry
    index: number
}

export default function LogEntryComponent(props: LogEntryProps) {
    return (
        <tr>
            <td style={styles.serialNumber}>{props.index + 1}</td>
            <td style={styles.centerText}>
                <div>{new Date(props.logEntry.timestamp).toLocaleTimeString()}</div>
                <div>{new Date(props.logEntry.timestamp).toLocaleDateString()}</div>
            </td>
            <div style={styles.testDetails}>
                <td>{props.logEntry.module}</td>
                <td>Set {props.logEntry.setNumber}</td>
            </div>
            <td style={styles.centerText}>{props.logEntry.correctQuestionCount}</td>
            <td style={styles.centerText}>{props.logEntry.totalQuestions}</td>
        </tr>
    )
}

const styles = {
    testDetails: {
        display: 'flex',
        flexDirection: 'column' as 'column',
        paddingLeft: '2em',
    },
    serialNumber: {
        fontStyle: ThemeConstants.headingFont,
        fontSize: '1.2em',
        marginRight: '1em',
        textAlign: 'center' as 'center',
    },
    centerText: {
        textAlign: 'center' as 'center'
    },
}
