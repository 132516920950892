import React from 'react'

export default function LogHeader() {
    return (
        <tr>
            <th>#</th>
            <th>Timestamp</th>
            <th>Test</th>
            <th>Correct</th>
            <th>Total</th>
        </tr>
    )
}
