import {AppDispatch, AppThunk, RootState} from '../../common/redux/store'
import TestService from '../service/TestService'
import {changeTestData, valueQuestions} from './slice'
import SelectionFilter from "../../common/model/SelectionFilter";
import Test from "../../common/model/Test";
import NavigationService from "../../common/service/NavigationService";
import {changeAnswerKey, changeScore} from "../../result/redux/slice";

export const submitTest = (): AppThunk => async (
    dispatch: AppDispatch, getState: () => RootState
): Promise<void> => {
    const state: RootState = getState()
    dispatch(valueQuestions())
    await TestService.saveTest(
        {
            ...state.test.testData,
            timeTaken: 3 * 3600 - state.test.time,
        },
        {
            testType: state.selection.testType,
            module: state.selection.module,
            setNumber: state.selection.setNumber
        }
    )
    dispatch(changeScore(state.test.testData))
    dispatch(changeAnswerKey(state.test.testData))
    NavigationService.navigateToScoreCard()
}

export const startTest = (): AppThunk => async (
    dispatch: AppDispatch, getState: () => RootState
): Promise<void> => {
    const state: RootState = getState()
    const selectionFilter: SelectionFilter = new SelectionFilter(
        state.selection.testType,
        state.selection.module,
        state.selection.setNumber
    )
    const test: Test = await TestService.fetchTest(selectionFilter)
    dispatch(changeTestData(test))
    NavigationService.navigateToTest()
}