import {AppDispatch, AppThunk, RootState} from '../../common/redux/store'
import User from "../../common/model/User";
import profileService from "../service/ProfileService";
import {changeProfile} from "./slice";

export const getProfile = (): AppThunk => async (
    dispatch: AppDispatch,
    getState: () => RootState,
): Promise<void> => {
    const user: User = await profileService.getProfile()
    dispatch(changeProfile(user))
}

export const saveProfile = (): AppThunk => async (
    dispatch: AppDispatch,
    getState: () => RootState,
): Promise<void> => {
    const user: User = getState().profile.modifiedUser
    await profileService.saveProfile(user)
    dispatch(changeProfile(user))
}
