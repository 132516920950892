import Module from './Module'

export default class SelectionFilter {

    constructor(testType: TestType | null, module: Module | null, setNumber: number | null) {
        this.testType = testType
        this.module = module
        this.setNumber = setNumber
    }

    testType: TestType | null = null
    module: Module | null = null
    setNumber: number | null = null

    static changeTestType = (selectionFilter: SelectionFilter, testType: TestType): SelectionFilter => ({
        testType: testType,
        module: testType === 'MODULE' ? selectionFilter.module : null,
        setNumber: testType === 'SET' ? selectionFilter.setNumber : null,
    })

    static changeModule = (selectionFilter: SelectionFilter, module: Module): SelectionFilter => ({
        testType: selectionFilter.testType,
        module: module,
        setNumber: null,
    })

    static changeSetNumber = (selectionFilter: SelectionFilter, setNumber: number): SelectionFilter => ({
        testType: selectionFilter.testType,
        module: selectionFilter.module,
        setNumber: setNumber,
    })

    static isValid(selectionFilter: SelectionFilter): boolean {
        if (selectionFilter.testType === 'FULL') {
            return true
        } else if (selectionFilter.testType === 'MODULE' && selectionFilter.module) {
            return true
        } else return !!(selectionFilter.testType === 'SET' &&
            selectionFilter.module &&
            selectionFilter.setNumber != null);
    }
}

export type TestType = 'SET' | 'MODULE' | 'FULL'
