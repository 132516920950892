import * as React from 'react'
import QuestionInstance from '../../../common/model/QuestionInstance'
import ButtonStyle from '../../../common/data/styles/ButtonStyle'
import {Button} from '@blueprintjs/core'

interface Props {
  questionInstanceList: QuestionInstance[]
  currentQuestionNumber: number
  nextQuestion: () => void
}

export default function NextButton(props: Props) {
  return (
    <Button
      style={ButtonStyle}
      disabled={props.currentQuestionNumber === props.questionInstanceList.length - 1}
      text={'Next'}
      onClick={props.nextQuestion}
      rightIcon={'chevron-right'}
      outlined
    />
  )
}
