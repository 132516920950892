import React from 'react'
// @ts-ignore
import {isDesktop} from 'react-device-detect'
import Question from '../../../common/model/Question'
import ThemeConstants from '../../../common/data/styles/ThemeConstants'

interface QuestionBlockProps {
  question: string
  index: number
}

export default function QuestionBlock(props: QuestionBlockProps) {
  const styles = isDesktop ? stylesDesktop : stylesMobile

  return (
    <div style={styles.questionBlock}>
      <div style={styles.questionNumber}>{props.index + 1 + '. '}</div>
      <div style={styles.question}>{Question.formatText(props.question)}</div>
    </div>
  )
}

const stylesDesktop = {
  questionBlock: {
    display: 'flex',
    flexDirection: 'row' as 'row',
    marginBottom: '1em',
    marginTop: '3em',
  },
  question: {
    fontSize: '1.3em',
    // color: 'white',
  },
  questionNumber: {
    fontSize: '1.3em',
    // color: 'white',
    fontFamily: ThemeConstants.headingFont,
    marginRight: '0.6vw',
  },
}

const stylesMobile = {
  questionBlock: {
    display: 'flex',
    flexDirection: 'row' as 'row',
    marginBottom: '3vh',
    marginTop: '6vh',
  },
  question: {
    fontSize: '2vh',
  },
  questionNumber: {
    fontSize: '2vh',
    fontWeight: 800,
    marginRight: '0.6vh',
  },
}
