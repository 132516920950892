import Controller from "../../common/service/Controller";
import Module from "../../common/model/Module";

class SelectionService {

    async getModules(): Promise<Module[]> {
        const modules = await Controller.fetch('/test/get-modules')
        console.log(modules)
        return modules
    }

    async getModuleToSetCount(): Promise<Map<string, number>> {
        const jsonObject = await Controller.fetch('/test/get-module-set-count')
        return this.jsonToMap(jsonObject)
    }

    private jsonToMap(jsonObject: any): Map<string, number> {
        const map: Map<string, number> = new Map();
        for (const key in jsonObject) {
            if (jsonObject.hasOwnProperty(key)) {
                map.set(key, jsonObject[key]);
            }
        }
        return map;
    }

}

const selectionService: SelectionService = new SelectionService()

export default selectionService
