import store from "../redux/store"
import {changeLocation} from "../redux/slice"
import {getCompletionStatus, getLeaderboardEntries, getLogEntries} from "../../dashboard/redux/thunks"
import {getProfile} from "../../profile/redux/thunks"
import {getModules, getModuleToSetCount} from "../../selection/redux/thunks";

class NavigationService {

    navigateToDashboard() {
        store.dispatch(getLeaderboardEntries())
        store.dispatch(getLogEntries())
        store.dispatch(getCompletionStatus())
        store.dispatch(changeLocation("dashboard"))
    }

    navigateToLogin() {
        store.dispatch(changeLocation("login"))
    }

    navigateToRegister() {
        store.dispatch(changeLocation("register"))
    }

    navigateToSelection() {
        store.dispatch(getModules())
        store.dispatch(getModuleToSetCount())
        store.dispatch(changeLocation("selection"))
    }

    navigateToTest() {
        store.dispatch(changeLocation("test"))
    }

    navigateToScoreCard() {
        store.dispatch(changeLocation("score-card"))
    }

    navigateToAnswerKey() {
        store.dispatch(changeLocation("answer-key"))
    }

    navigateToProfile() {
        store.dispatch(getProfile())
        store.dispatch(changeLocation("profile"))
    }
}

const navigationService: NavigationService = new NavigationService()

export default navigationService