import store from '../redux/store';
import AuthenticationService from "../../authentication/common/service/AuthenticationService";
import NavigationService from "./NavigationService";

export default class Controller {
    static API = 'https://31ga8jabn1.execute-api.ap-south-1.amazonaws.com/dev/';
    // static API = 'http://localhost:3000';

    static async fetch(url: string, payload: any = {}): Promise<any> {
        try {
            const res = await this.fetchCall(url, payload);
            let resJson = await res.json();
            if (resJson.message === 'JWT Expired') {
                console.log('JWT Expired');
                await AuthenticationService.renewJWT();
                const res = await this.fetchCall(url, payload);
                resJson = await res.json();
            } else if (resJson.message) {
                throw new Error(resJson.message);
            }
            return resJson;
        } catch (e: any) {
            console.log(e.message);
            if (e.message === 'Network request failed') {
                throw new Error('No Internet Connectivity');
            } else if (e.message === 'Refresh Token Invalid') {
                alert('Session Expired. Kindly login in again');
                NavigationService.navigateToLogin();
                throw new Error('Session Expired');
            } else {
                throw e
            }
        }
    }

    static async fetchVoid(url: string, payload: any): Promise<void> {
        try {
            const res: any = await this.fetchCall(url, payload);
            if (res) {
                try {
                    const resJson = await res.json();
                    if (resJson.message === 'JWT Expired') {
                        await AuthenticationService.renewJWT();
                    }
                    const res2: any = await this.fetchCall(url, payload);
                    if (res2) {
                        const resJson2 = await res2.json();
                        throw new Error(resJson2.message);
                    }
                } catch (e) {}
            }
        } catch (e) {
            throw new Error('No Internet Connectivity');
        }
    }

    static async fetchCall(url: string, payload: any): Promise<any> {
        const jwt: string = store.getState().authentication.jwt;
        return fetch(Controller.API + url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + jwt,
            },
            body: JSON.stringify({
                ...payload,
            }),
        });
    }
}
