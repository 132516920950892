import {TestType} from '../../common/model/SelectionFilter'
import Module from '../../common/model/Module'
import moduleList from '../../common/data/prototype/moduleList'

interface InitialState {
    module: Module | null
    setNumber: number | null
    testType: TestType | null
    moduleList: Module[]
    moduleToSetCountMap: Map<string, number>
    setCount: number
}

const initialState: InitialState = {
    testType: null,
    module: null,
    setNumber: null,
    moduleList: moduleList,
    moduleToSetCountMap: new Map(),
    setCount: 0,
}

export default initialState
