import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import initialState from './initialState'
import User from "../../common/model/User";

const Slice = createSlice({
    name: 'Profile',
    initialState,
    reducers: {
        changeProfile(state, action: PayloadAction<User>) {
            state.user = action.payload
            state.modifiedUser = action.payload
        },

        changeName(state, action: PayloadAction<string>) {
            state.modifiedUser.name = action.payload
        },

        changeLocation(state, action: PayloadAction<string>) {
            state.modifiedUser.location = action.payload
        },

        resetUser(state, action: PayloadAction) {
            state.modifiedUser.name = state.user.name
            state.modifiedUser.location = state.user.location
        }
    },
})

export default Slice.reducer

export const {
    changeName,
    changeLocation,
    resetUser,
    changeProfile
} = Slice.actions
