import * as React from 'react'
import NextButton from './NextButton'
import PreviousButton from './PreviousButton'
import SubmitButton from './SubmitButton'
import {isDesktop} from 'react-device-detect'
import QuestionInstance from '../../../common/model/QuestionInstance'
import ThemeConstants from '../../../common/data/styles/ThemeConstants'
import Test from '../../../common/model/Test'

interface Props {
  questionInstanceList: QuestionInstance[]
  currentQuestionNumber: number
  testData: Test
  nextQuestion: () => void
  previousQuestion: () => void
  submitTest: () => void
}

export default function ControlPanel(props: Props) {
  const styles = isDesktop ? stylesDesktop : stylesMobile
  return (
    <div style={styles.container}>
      <div style={styles.subContainer}>
        <PreviousButton currentQuestionNumber={props.currentQuestionNumber} previousQuestion={props.previousQuestion} />
        <NextButton
          questionInstanceList={props.questionInstanceList}
          currentQuestionNumber={props.currentQuestionNumber}
          nextQuestion={props.nextQuestion}
        />
      </div>
      <span style={styles.currentQuestionNumber}>
        {props.currentQuestionNumber + 1} / {props.testData.questionList.length}
      </span>
      {isDesktop ? <SubmitButton submitTest={props.submitTest} /> : <div />}
    </div>
  )
}

const stylesDesktop = {
  container: {
    paddingLeft: '5vw',
    paddingRight: '5vw',
    marginTop: '0.5vw',
    display: 'flex',
    flex: 1,
    flexDirection: 'row' as 'row',
    justifyContent: 'space-between',
    maxWidth: '100%',
    alignItems: 'center',
  },
  currentQuestionNumber: {
    fontSize: '1vw',
    color: '#5F6B7C',
    fontFamily: ThemeConstants.headingFont,
    paddingRight: '1.2vw',
  },
  subContainer: {},
}

const stylesMobile = {
  container: {
    paddingLeft: '3vh',
    paddingRight: '3vh',
    paddingBottom: '4vh',
    marginTop: '1vh',
    display: 'flex',
    flex: 1,
    flexDirection: 'row' as 'row',
    justifyContent: 'space-between',
    width: '100%',
  },
  currentQuestionNumber: {
    fontSize: '1vw',
    // color: 'white',
    fontFamily: ThemeConstants.headingFont,
    paddingRight: '1.2vw',
  },
  subContainer: {
    display: 'flex',
    height: 'fit-content' as 'fit-content',
    marginTop: '4vh',
    width: '100%',
    flexDirection: 'row' as 'row',
    justifyContent: 'space-between',
  },
}
