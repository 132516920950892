
import {AppDispatch, AppThunk, RootState} from "../../common/redux/store";
import Module from "../../common/model/Module";
import selectionService from "../srevice/SelectionService";
import {changeModuleList, changeModuleSetCountMap} from "./slice";

export const getModules = (): AppThunk => async (
    dispatch: AppDispatch,
    getState: () => RootState,
): Promise<void> => {
    const modules: Module[] = await selectionService.getModules()
    dispatch(changeModuleList(modules))
}

export const getModuleToSetCount = (): AppThunk => async (
    dispatch: AppDispatch,
    getState: () => RootState,
): Promise<void> => {
    const moduleToSetCount: Map<string, number> = await selectionService.getModuleToSetCount()
    dispatch(changeModuleSetCountMap(moduleToSetCount))
}
