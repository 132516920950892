import Test, {TestManipulator} from '../../common/model/Test'
import {QuestionKey, questionKeyList} from '../../common/model/QuestionKey'
import Score from "../../common/model/Score";

interface InitialState {
  testData?: Test
  score: Score
  questionList: QuestionKey[]
}

const initialState: InitialState = {
  testData: TestManipulator.getMockInstance(),
  score: {
    score: 0,
    correctCount: 0,
    incorrectCount: 0,
    unansweredCount: 0,
  },
  questionList: questionKeyList,
}

export default initialState
