import React from 'react'
import CompletionStatusView from './CompletionStatusView'
import {Card, Intent} from '@blueprintjs/core'
import CompletionStatus from "../../../common/model/CompletionStatus";
import {isDesktop} from "react-device-detect";

interface CompletionStatusDataProps {
    completionStatusList: CompletionStatus[]
}

export default function CompletionStatusData(props: CompletionStatusDataProps) {
    if (isDesktop) {
        return (
            <Card style={styles.container}>
                {props.completionStatusList.map((completionStatus: CompletionStatus, index: number) => (
                    <CompletionStatusView completionStatus={completionStatus} intent={getIntent(index)}/>
                ))}
            </Card>
        )
    } else {
        return <Card>
            <h3>Completion Status</h3>
            {props.completionStatusList.map((completionStatus: CompletionStatus, index: number) => (
                <CompletionStatusView completionStatus={completionStatus} intent={getIntent(index)}/>
            ))}
        </Card>
    }
}

const getIntent = (value: number): Intent => {
    switch (value % 3) {
        case 0:
            return Intent.SUCCESS
        case 1:
            return Intent.PRIMARY
        case 2:
            return Intent.DANGER
        default:
            return Intent.NONE
    }
}

const styles = {
    container: {
        display: 'flex' as 'flex',
        height: '100%',
        width: '50%',
        margin: '2em',
        backgroundColor: '#fff',
        borderRadius: '5px',
        padding: '1em',
        flexDirection: 'column' as 'column',
        overflowY: 'auto' as 'auto',
    }
}
