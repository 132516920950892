import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import initialState from './initialState'
import User from "../../../common/model/User";

const Slice = createSlice({
    name: 'Authentication',
    initialState,
    reducers: {
        changeLoginUsername(state, action: PayloadAction<string>) {
            state.login.username = action.payload
        },

        changeLoginPassword(state, action: PayloadAction<string>) {
            state.login.password = action.payload
        },

        changeJwt(state, action: PayloadAction<string>) {
            state.jwt = action.payload
        },

        changeRefreshToken(state, action: PayloadAction<string>) {
            state.refreshToken = action.payload
        },

        changeUserName(state, action: PayloadAction<string>) {
            state.register.user.username = action.payload
        },

        changeName(state, action: PayloadAction<string>) {
            state.register.user.name = action.payload
        },

        changeLocation(state, action: PayloadAction<string>) {
            state.register.user.location = action.payload
        },

        changeMobileNumber(state, action: PayloadAction<string>) {
            state.register.user.mobileNumber = action.payload
        },

        changePassword(state, action: PayloadAction<string>) {
            state.register.password = action.payload
        },

        changeConfirmPassword(state, action: PayloadAction<string>) {
            state.register.confirmPassword = action.payload
        }
    },
})

export default Slice.reducer

export const {
    changeLoginUsername,
    changeLoginPassword,
    changeUserName,
    changeName,
    changeLocation,
    changeMobileNumber,
    changePassword,
    changeConfirmPassword,
    changeJwt,
    changeRefreshToken
} = Slice.actions
