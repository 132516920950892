import {connect} from 'react-redux'
import {
    changeConfirmPassword,
    changeLocation,
    changeMobileNumber,
    changeName,
    changePassword,
    changeUserName
} from '../../common/redux/slice'
import {AppDispatch, RootState} from '../../../common/redux/store'
import Register from '../components/Register'
import {registerUser} from "../../common/redux/thunks";
import NavigationService from "../../../common/service/NavigationService";

const mapStateToProps = (state: RootState) => ({
    user: state.authentication.register.user,
    password: state.authentication.register.password,
    confirmPassword: state.authentication.register.confirmPassword
})

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    changeUserName: (value: string) => {
        dispatch(changeUserName(value))
    },

    changeName: (value: string) => {
        dispatch(changeName(value))
    },

    changeLocation: (value: string) => {
        dispatch(changeLocation(value))
    },

    changeMobileNumber: (value: string) => {
        dispatch(changeMobileNumber(value))
    },

    changePassword: (password: string) => {
        dispatch(changePassword(password))
    },

    changeConfirmPassword: (password: string) => {
        dispatch(changeConfirmPassword(password))
    },

    registerUser: () => {
        dispatch(registerUser())
    },

    navigateToLogin: () => {
        NavigationService.navigateToLogin()
    }
})

export default connect(mapStateToProps, mapDispatchToProps)(Register)
