import React from 'react'
import {Button, H3, Intent} from '@blueprintjs/core'
import ButtonStyle from '../../common/data/styles/ButtonStyle'
import Module from '../../common/model/Module'

interface SetTestSelectionProps {
    moduleList: Module[]
    setCount: number
    module: Module | null
    setNumber: number | null
    changeModule: (module: Module) => void
    changeSetNumber: (setNumber: number) => void
}

export default function SetTestSelection(props: SetTestSelectionProps) {
    return (
        <div style={styles.container}>
            <div style={styles.moduleBox}>
                <H3 style={styles.heading}>Select Module</H3>
                {props.moduleList.map((module) => (
                    <Button
                        text={module.name}
                        minimal
                        style={styles.moduleButton}
                        alignText={'left'}
                        onClick={() => {
                            props.changeModule(module)
                        }}
                        icon={props.module && props.module === module ? 'tick-circle' : undefined}
                        intent={props.module === module ? Intent.PRIMARY : Intent.NONE}
                    />
                ))}
            </div>
            <div style={styles.setBox}>
                <H3 style={styles.setHeading}>Select Set</H3>
                {Array.from({length: props.setCount}, (_, i) => i + 1).map((setNumber) => (
                    <Button
                        text={setNumber}
                        outlined
                        style={styles.setButton}
                        alignText={'center'}
                        onClick={() => {
                            console.log(setNumber)
                            props.changeSetNumber(setNumber)
                        }}
                        intent={props.setNumber === setNumber - 1 ? Intent.PRIMARY : Intent.NONE}
                    />
                ))}
            </div>
        </div>
    )
}

const styles = {
    moduleButton: {
        ...ButtonStyle,
        marginBottom: '0',
        marginLeft: '0.5em',
        paddingLeft: '1em',
    },
    heading: {
        paddingLeft: '1em',
        marginBottom: '0',
        marginTop: '1em',
    },
    moduleBox: {
        display: 'flex',
        flexDirection: 'column' as 'column',
        alignSelf: 'flex-start' as 'flex-start',
        backgroundColor: 'white',
        margin: '1em',
        marginTop: '0',
        width: '33%',
        height: '100%',
        overflowY: 'auto' as 'auto',
    },
    container: {
        display: 'flex',
        height: '85%',
        width: '100%',
        flexDirection: 'row' as 'row',
    },
    setBox: {
        alignSelf: 'flex-start',
        backgroundColor: 'white',
        margin: '1em',
        marginTop: '0',
        width: '33%',
        height: '100%',
        overflowY: 'auto' as 'auto',
        paddingLeft: '1em',
    },
    setButton: {
        ...ButtonStyle,
        marginBottom: '0',
        marginLeft: '0.5em',
        paddingLeft: '1em',
        width: '2.5em',
        height: '2.5em',
    },
    setHeading: {
        marginBottom: '0',
        marginTop: '1em',
    },
}
