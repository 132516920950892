import {createSlice, current, PayloadAction} from '@reduxjs/toolkit'
import initialState from './initialState'
import {TestType} from '../../common/model/SelectionFilter'
import Module from '../../common/model/Module'

const Slice = createSlice({
    name: 'Selection',
    initialState,
    reducers: {
        changeTestType(state, action: PayloadAction<TestType>) {
            state.testType = action.payload
            state.module = null
            state.setNumber = null
            state.setCount = 0
        },

        changeModule(state, action: PayloadAction<Module>) {
            state.module = action.payload
            state.setNumber = null
            state.setCount = 0
            const moduleId = state.module?.id
            if (moduleId) {
                const setCount = state.moduleToSetCountMap.get(moduleId)
                if (setCount) {
                    state.setCount = setCount
                }
            }
        },

        changeModuleList(state, action: PayloadAction<Module[]>) {
            state.moduleList = action.payload
        },

        changeModuleSetCountMap(state, action: PayloadAction<Map<string, number>>) {
            state.moduleToSetCountMap = action.payload
        },

        changeSetNumber(state, action: PayloadAction<number>) {
            const moduleId = state.module?.id
            if (moduleId) {
                const setCount = state.moduleToSetCountMap.get(moduleId)
                if (setCount && setCount > action.payload) {
                    state.setNumber = action.payload
                }
            }
        },
    },
})

export default Slice.reducer

export const {
    changeModule,
    changeTestType,
    changeSetNumber,
    changeModuleSetCountMap,
    changeModuleList,
} = Slice.actions
