import React from 'react'
import {Card, H3} from '@blueprintjs/core'
import ThemeConstants from '../../common/data/styles/ThemeConstants'

interface Props {
    children?: React.ReactNode
}

export default function ProfileBox(props: Props) {
    return (
        <div style={styles.container}>
            <Card style={styles.card}>
                <div style={styles.cardContent}>
                    <H3 style={styles.heading}>View/Edit Profile</H3>
                    {props.children}
                </div>
            </Card>
        </div>
    )
}

const styles = {
    cardContent: {padding: '1em', paddingTop: '0'},
    card: {
        padding: '1em',
        margin: '4em',
        height: 'fit-content',
    },
    container: {
        display: 'flex',
        flex: 1,
        backgroundColor: '#f9f9f9',
        minHeight: '100%',
        justifyContent: 'center',
    },
    heading: {
        fontWeight: '300',
        fontFamily: ThemeConstants.headingFont,
        marginTop: '1em',
        textAlign: 'center' as 'center',
    },
}
