import React from 'react'
import HeaderNavBar from '../../common/components/HeaderNavBar'
import {Button, Intent} from '@blueprintjs/core'
import User from "../../common/model/User";
import InputBlock from "./InputBlock";
import ProfileBox from "./ProfileBox";
import HeaderNavBarContainer from "../../common/containers/HeaderNavBarContainer";
import ScreenWithHeader from "../../common/components/ScreenWithHeader";

interface ProfileProps {
    user: User
    changeName: (name: string) => void
    changeLocation: (location: string) => void
    saveProfile: () => void
    resetProfile: () => void
}

export default function Profile(props: ProfileProps) {
    return (
        <ScreenWithHeader>
            <ProfileBox>
                <table>
                    <tbody>
                    <InputBlock icon={'id-number'} label={'Username'} value={props.user.username} disabled />
                    <InputBlock icon={'person'} label={'Name'} value={props.user.name} onChange={props.changeName} />
                    <InputBlock icon={'map-marker'} label={'Location'} value={props.user.location} onChange={props.changeLocation} />
                    <InputBlock icon={'offline'} label={'Score'} value={props.user.points.toString()} disabled />
                    </tbody>
                </table>
                <div style={styles.buttonBlock}>
                    <Button onClick={props.resetProfile} text={'Cancel'} icon={'remove'} minimal intent={Intent.DANGER} large />
                    <Button onClick={props.saveProfile} text={'Save Profile'} icon={'floppy-disk'} minimal intent={Intent.SUCCESS} large />
                </div>
            </ProfileBox>
        </ScreenWithHeader>
    )
}

const styles = {
    page: {
        height: '100%',
        width: '100%',
    },
    buttonBlock: {
        display: 'flex',
        justifyContent: 'space-between',
        marginTop: '2em',
    },
}
