import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import initialState from './initialState'
import Score from '../../common/model/Score'
import Test from '../../common/model/Test'
import Question from "../../common/model/Question";
import QuestionInstance from "../../common/model/QuestionInstance";

const Slice = createSlice({
    name: 'Result',
    initialState,
    reducers: {
        changeScore(state, action: PayloadAction<Test>) {
            const test = action.payload
            const correctCount: number = test.questionInstanceList.map((questionInstance: QuestionInstance, index: number) => {
                const selectedAnswer = questionInstance.selectedAnswer
                const answer = test.questionList[index].answer
                return selectedAnswer === answer ? 1 as number : 0
            }).reduce((acc, curr) => acc + curr)
            const unansweredCount = test.questionInstanceList.map((questionInstance: QuestionInstance, index: number) => {
                const selectedAnswer = questionInstance.selectedAnswer
                return selectedAnswer === '' ? 1 as number : 0
            }).reduce((acc, curr) => acc + curr)
            state.score = {
                score: correctCount,
                correctCount,
                incorrectCount: test.questionList.length - (correctCount + unansweredCount),
                unansweredCount
            }
        },
        changeAnswerKey(state, action: PayloadAction<Test>) {
            state.questionList = action.payload.questionList.map((questionInstance, index) => ({
                // @ts-ignore
                question: questionInstance.content.question,
                answer: questionInstance.answer,
                selectedAnswer: action.payload.questionInstanceList[index].selectedAnswer,
                supportImage: ''
            }))
        },
        changeTestData(state, action: PayloadAction<Test>) {
            state.testData = action.payload
        },
    },
})

export default Slice.reducer

export const {
    changeScore,
    changeAnswerKey,
    changeTestData
} = Slice.actions
