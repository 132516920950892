import React from 'react'
import {isDesktop} from 'react-device-detect'

interface SupportImageBlockProps {
  supportImage: string
}

const imageSrc = 'https://neetbio-images.s3.ap-south-1.amazonaws.com/'

export default function SupportImageBlock(props: SupportImageBlockProps) {
  const styles = isDesktop ? stylesDesktop : stylesMobile

  if (props.supportImage && props.supportImage.length) {
    return (
      <div style={styles.supportImageContainer}>
        <div style={styles.supportImage}>
          <img src={imageSrc + props.supportImage} alt={'support image'} />
        </div>
      </div>
    )
  }
  return <div />
}

const stylesDesktop = {
  supportImage: {
    backgroundColor: '#4f5259',
    padding: '1vw',
    marginLeft: '1.5vw',
    borderRadius: 5,
    height: 'fit-content',
    display: 'flex',
    flexDirection: 'column' as 'column',
    alignSelf: 'flex-start',
  },
  supportImageContainer: {
    display: 'flex',
    marginBottom: '1.2vw',
  },
}

const stylesMobile = {
  supportImage: {
    backgroundColor: '#4f5259',
    padding: '2vh',
    marginLeft: '3vh',
    borderRadius: '1vh',
    height: 'fit-content',
    display: 'flex',
    flexDirection: 'column' as 'column',
    alignSelf: 'flex-start',
  },
  supportImageContainer: {
    display: 'flex',
    marginBottom: '2vh',
  },
}
