import React from 'react'
import {Card} from "@blueprintjs/core";
import LogHeader from "./LogHeader";
import LogEntryComponent from "./LogEntryComponent";
import LogEntry from "../../../common/model/LogEntry";
import {isDesktop} from "react-device-detect";

interface LogProps {
    logEntryList: LogEntry[]
}

export default function Log(props: LogProps) {
    return (
        <Card style={isDesktop ? styles.container : {flex: 1}}>
            <div style={styles.headingBlock}>
                <h3 style={styles.heading}>Test History</h3>
            </div>
            <table style={ isDesktop ? styles.table : {...styles.table, width: '100%', justifyContent: "space-evenly"}}>
                <LogHeader />
                {
                    props.logEntryList.map((logEntry: LogEntry, index: number) => (
                        <LogEntryComponent logEntry={logEntry} index={index} />
                    ))
                }
            </table>
        </Card>
    )
}

const styles = {
    container: {
        display: 'flex',
        height: '50%',
        flexDirection: 'column' as 'column',
        overflowY: 'auto' as 'auto',
        marginTop: '2em',
        padding: '1em',
        backgroundColor: '#fff',
        borderRadius: '5px',
    },
    table: {
        maxHeight: '100%',
        overflowY: 'auto' as 'auto',
    },
    headingBlock: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    heading: {
        marginTop: '0.5em',
        marginRight: '2em',
    },
}
