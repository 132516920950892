import React from 'react'
import LogContainer from '../containers/LogContainer'
import LeaderboardContainer from '../containers/LeaderboardContainer'
import CompletionStatusDataContainer from '../containers/CompletionStatusDataContainer'
import ScreenWithHeader from '../../common/components/ScreenWithHeader'
import {isDesktop} from "react-device-detect";

export default function Dashboard() {
    if (isDesktop) {
        return (
            <ScreenWithHeader>
                <div style={styles.page}>
                    <CompletionStatusDataContainer/>
                    <div
                        style={styles.rightPanel}>
                        <LeaderboardContainer/>
                        <LogContainer/>
                    </div>
                </div>
            </ScreenWithHeader>
        )
    } else {
        return (
            <ScreenWithHeader>
                <CompletionStatusDataContainer/>
                <LeaderboardContainer/>
                <LogContainer/>
            </ScreenWithHeader>
        )
    }
}

const styles = {
    page: {
        display: 'flex' as 'flex',
        flexDirection: 'row' as 'row',
        height: '90%',
        width: '100%'
    },
    rightPanel: {
        display: 'flex' as 'flex',
        flexDirection: 'column' as 'column',
        height: '100%',
        width: '50%',
        margin: '2em',
        marginLeft: '0',
    }
}
