import React, {useEffect} from 'react'
import ControlPanel from './ControlPanel/ControlPanel'
import QuestionRenderer from './QuestionRenderer'
import Header from './Header'
import Test from '../../common/model/Test'
import {Card} from '@blueprintjs/core'
import HeaderNavBarContainer from "../../common/containers/HeaderNavBarContainer";
import ScreenWithHeader from "../../common/components/ScreenWithHeader";

interface TestScreenProps {
    testData: Test
    questionNumber: number
    time: number
    interval: any
    changeTestData: (testData: any) => void
    setInterval: (interval: number) => void
    reduceTime: () => void
    nextQuestion: () => void
    previousQuestion: () => void
    submitTest: () => void
    selectAnswer: (answer: string) => void
}

export default function TestScreen(props: TestScreenProps) {

    useEffect(() => {
        // if (props.interval) {
        //   window.clearInterval(props.interval)
        // }
        // const currentInterval = window.setInterval(() => {
        //   props.reduceTime()
        // }, 1000)
        // props.setInterval(currentInterval)
    }, [props])

    useEffect(() => {
        // if (!props.time) {
        //   props.submitTest(navigate)
        // }
    }, [props, props.time])

    return (
        <ScreenWithHeader>
            <Card style={styles.container}>
                <Header
                    time={props.time}
                    submitTest={() => {
                        props.submitTest()
                    }}
                    questionNumber={props.questionNumber}
                    setTestData={props.changeTestData}
                    testData={props.testData}
                />
                <QuestionRenderer
                    question={props.testData.questionList[props.questionNumber]}
                    questionInstance={props.testData.questionInstanceList[props.questionNumber]}
                    selectAnswer={props.selectAnswer}
                />
                <ControlPanel
                    testData={props.testData}
                    questionInstanceList={props.testData.questionInstanceList}
                    currentQuestionNumber={props.questionNumber}
                    nextQuestion={props.nextQuestion}
                    previousQuestion={props.previousQuestion}
                    submitTest={props.submitTest}
                />
            </Card>
        </ScreenWithHeader>
    )
}

const styles = {
    body: {
        minHeight: '100%',
        backgroundSize: 'cover',
        display: 'flex',
        flexDirection: 'column' as 'column',
        flex: 1,
        backgroundColor: 'url(./bg.png)',
    },
    container: {
        width: 'match-parent',
        margin: '',
        padding: '0em',
    },
}
