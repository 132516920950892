import {connect} from 'react-redux'
import {AppDispatch, RootState} from '../../common/redux/store'
import {changeModule, changeSetNumber} from '../redux/slice'
import SetTestSelection from '../components/SetTestSelection'
import Module from '../../common/model/Module'

const mapStateToProps = (state: RootState) => ({
  module: state.selection.module,
  setNumber: state.selection.setNumber,
  moduleList: Array.from(state.selection.moduleList),
  setCount: state.selection.setCount,
})

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  changeModule(module: Module) {
    dispatch(changeModule(module))
  },
  changeSetNumber(setNumber: number) {
    dispatch(changeSetNumber(setNumber))
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(SetTestSelection)
