import React from 'react'
import {Button, Intent} from '@blueprintjs/core'

interface Props {
    label: string
    action: string
    icon: string
    onClick: () => void
}

export default function AlternateOption(props: Props) {
    return (
        <div style={styles.container}>
            <div>
                {props.label}
            </div>
            <Button
                minimal
                intent={Intent.PRIMARY}
                text={props.action}
                rightIcon={props.icon}
                onClick={props.onClick}
            />
        </div>
    )
}

const styles = {
    container: {
        display: 'flex',
        alignItems: 'center',
        marginTop: 20
    },
}
