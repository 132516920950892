import {AppThunk, AppDispatch, RootState} from "../../../common/redux/store";
import AuthenticationResponse from "../model/AuthenticationResponse";
import AuthenticationService from "../service/AuthenticationService";
import {changeJwt, changeRefreshToken} from "./slice";
import NavigationService from "../../../common/service/NavigationService";
import {RegisterState} from "./initialState";

export const loginUser = (): AppThunk => async (
    dispatch: AppDispatch,
    getState: () => RootState,
): Promise<void> => {
    try {
        const state: RootState = getState();
        const authenticationResponse: AuthenticationResponse = await AuthenticationService.loginUser(
            state.authentication.login.username,
            state.authentication.login.password,
        );
        handleAuthResponse(dispatch, authenticationResponse)
    } catch (error: any) {
        alert('Unable to login: ' + error.message);
    }
}

export const logoutUser = (): AppThunk => async (
    dispatch: AppDispatch,
    getState: () => RootState,
): Promise<void> => {
    dispatch(changeJwt(''))
    dispatch(changeRefreshToken(''))
    NavigationService.navigateToLogin()
}

export const registerUser = (): AppThunk => async (
    dispatch: AppDispatch,
    getState: () => RootState,
): Promise<void> => {
    try {
        const state: RootState = getState();
        validatePassword(state.authentication.register)
        const authenticationResponse: AuthenticationResponse = await AuthenticationService.registerUser(
            state.authentication.register.user,
            state.authentication.register.password,
        );
        handleAuthResponse(dispatch, authenticationResponse)
    } catch (error: any) {
        alert('Unable to register: ' + error.message);
    }
}

const handleAuthResponse = (dispatch: AppDispatch, authenticationResponse: AuthenticationResponse) => {
    dispatch(changeJwt(authenticationResponse.jwt))
    dispatch(changeRefreshToken(authenticationResponse.refreshToken))
    NavigationService.navigateToDashboard()
}

const validatePassword = (registerState: RegisterState) => {
    if (registerState.password.trim() !== registerState.confirmPassword.trim()) {
        throw Error('Passwords do not match')
    }
}
