import React from 'react'
import User from "../../../common/model/User";
import LeaderboardEntry from "./LeaderboardEntry";
import {Card} from "@blueprintjs/core";
import {isDesktop} from "react-device-detect";

interface LeaderboardProps {
    leaderboardEntries: User[]
}

export default function Leaderboard(props: LeaderboardProps) {
    return (
        <Card style={ isDesktop ? styles.container : {}}>
            <div style={styles.topBar}>
                <h3 style={styles.heading}>Leaderboard</h3>
            </div>
            <table>
                {
                    props.leaderboardEntries.map((leaderboardEntry: User, index: number) =>
                        <LeaderboardEntry leaderboardEntry={leaderboardEntry} index={index} />
                    )
                }
            </table>
        </Card>
    )
}

const styles = {
    container: {
        display: 'flex',
        flexDirection: 'column' as 'column',
        overflowY: 'auto' as 'auto',
        height: '50%',
        padding: '1em',
        backgroundColor: '#fff',
        borderRadius: '5px',
    },
    heading: {
        marginTop: '0.5em',
        marginRight: '2em',
    },
    topBar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
}
