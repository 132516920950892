import React from 'react'
import ButtonStyle from '../../../common/data/styles/ButtonStyle'
import ThemeConstants from '../../../common/data/styles/ThemeConstants'
import {Button, Card, InputGroup, Intent} from '@blueprintjs/core'
import AuthenticationPage from "../../common/components/AuthenticationPage"
import AlternateOption from "../../common/components/AlternateOption"
import {isDesktop} from 'react-device-detect'

interface LoginProps {
    changeUsername: (username: string) => void
    changePassword: (password: string) => void
    attemptLogin: () => void
    navigateToRegister: () => void
}

export default function Login(props: LoginProps) {
    function getLoginBox() {
        return <div>
            <h1 style={styles.heading}>Login</h1>
            <div style={styles.inputBlock}>
                <InputGroup
                    leftIcon={'person'}
                    placeholder={'Username'}
                    large
                    onChange={(e) => {
                        props.changeUsername(e.target.value)
                    }}
                    style={{marginBottom: '1em'}}
                />
                <InputGroup
                    leftIcon={'key'}
                    placeholder={'Password'}
                    large
                    type={"password"}
                    onChange={(e) => {
                        props.changePassword(e.target.value)
                    }}
                />
            </div>
            <Button
                style={{...ButtonStyle}}
                text={'Enter'}
                rightIcon={'log-in'}
                intent={Intent.SUCCESS}
                large
                onClick={props.attemptLogin}
            />
            <AlternateOption
                label={'Not a member yet?'}
                action={'Register'}
                icon={'new-person'}
                onClick={props.navigateToRegister}
            />
        </div>;
    }

    if (isDesktop) {
        return (
            <AuthenticationPage>
                <Card style={styles.loginBox}>
                    {getLoginBox()}
                </Card>
            </AuthenticationPage>
        )
    } else {
        return <div style={{...styles.container, justifyContent: 'center', alignContent: "center", width: "100%"}}>
            {getLoginBox()}
        </div>
    }
}

const styles = {
    logo: {
        maxHeight: '10em',
        maxWidth: '50em',
        scale: '',
        margin: '2em',
    },
    quote: {
        marginTop: '-1em',
        marginBottom: '3em',
        color: '#5F6B7C',
    },
    quoteLine: {
        marginLeft: '4em',
        fontFamily: "'Poppins', sans-serif",
        fontSize: '2.5em',
    },
    heading: {
        fontFamily: ThemeConstants.headingFont,
        fontWeight: 200,
        fontSize: '3.5em',
    },
    container: {
        height: '100%',
        display: 'flex',
        alignItems: 'center',
    },
    leftPanel: {
        display: 'flex',
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column' as 'column',
        height: '100%',
    },
    rightPanel: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column' as 'column',
        alignItems: 'center',
        height: '100%',
        justifyContent: 'center',
    },
    inputBlock: {
        display: 'flex',
        flexDirection: 'column' as 'column',
        alignItems: 'center' as 'center',
        width: '100%'
    },
    loginBox: {
        padding: '2em',
        display: 'flex',
        flexDirection: 'column' as 'column',
        alignItems: 'center',
        paddingRight: '5em',
        paddingLeft: '5em',
    },
}
