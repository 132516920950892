import questionList from '../data/prototype/questionList'
import Question from './Question'
import {MCQ} from './QuestionContent'

export interface QuestionKey {
    question: string
    answer: string
    selectedAnswer: string
    supportImage: string
}

export const questionKeyList: QuestionKey[] = questionList.map((question: Question) => ({
    question: (question.content as MCQ).question,
    answer: question.answer,
    selectedAnswer: question.answer,
    supportImage: '',
}))

// [
//   {
//     question: "Which is the most boring game you've ever played?",
//     answer: "Call of Duty: Mobile",
//     selectedAnswer: "Free Fire",
//     supportImage: ""
//   },
//   {
//     question: "Which is the most boring game you've ever played?",
//     answer: "Call of Duty: Mobile",
//     selectedAnswer: "Free Fire",
//     supportImage: ""
//   },
//   {
//     question: "Which is the most boring game you've ever played?",
//     answer: "Call of Duty: Mobile",
//     selectedAnswer: "Free Fire",
//     supportImage: ""
//   },
//   {
//     question: "Which is the most boring game you've ever played?",
//     answer: "Call of Duty: Mobile",
//     selectedAnswer: "Free Fire",
//     supportImage: ""
//   },
//   {
//     question: "Which is the most boring game you've ever played?",
//     answer: "Call of Duty: Mobile",
//     selectedAnswer: "Free Fire",
//     supportImage: ""
//   },
//   {
//     question: "Which is the most boring game you've ever played?",
//     answer: "Call of Duty: Mobile",
//     selectedAnswer: "Free Fire",
//     supportImage: ""
//   },
//   {
//     question: "Which is the most boring game you've ever played?",
//     answer: "Call of Duty: Mobile",
//     selectedAnswer: "Free Fire",
//     supportImage: ""
//   },
//   {
//     question: "Which is the most boring game you've ever played?",
//     answer: "Call of Duty: Mobile",
//     selectedAnswer: "Free Fire",
//     supportImage: ""
//   },
//   {
//     question: "Which is the most boring game you've ever played?",
//     answer: "Call of Duty: Mobile",
//     selectedAnswer: "Free Fire",
//     supportImage: ""
//   },
//   {
//     question: "Which is the most boring game you've ever played?",
//     answer: "Call of Duty: Mobile",
//     selectedAnswer: "Free Fire",
//     supportImage: ""
//   },
//   {
//     question: "Which is the most boring game you've ever played?",
//     answer: "Call of Duty: Mobile",
//     selectedAnswer: "Free Fire",
//     supportImage: ""
//   },
//   {
//     question: "Which is the most boring game you've ever played?",
//     answer: "Call of Duty: Mobile",
//     selectedAnswer: "Free Fire",
//     supportImage: ""
//   },
//   {
//     question: "Which is the most boring game you've ever played?",
//     answer: "Call of Duty: Mobile",
//     selectedAnswer: "Free Fire",
//     supportImage: ""
//   },
//   {
//     question: "Which is the most boring game you've ever played?",
//     answer: "Call of Duty: Mobile",
//     selectedAnswer: "Free Fire",
//     supportImage: ""
//   },
//   {
//     question: "Which is the most boring game you've ever played?",
//     answer: "Call of Duty: Mobile",
//     selectedAnswer: "Free Fire",
//     supportImage: ""
//   },
//   {
//     question: "Which is the most boring game you've ever played?",
//     answer: "Call of Duty: Mobile",
//     selectedAnswer: "Free Fire",
//     supportImage: ""
//   },
//   {
//     question: "Which is the most boring game you've ever played?",
//     answer: "Call of Duty: Mobile",
//     selectedAnswer: "Free Fire",
//     supportImage: ""
//   },
// ]
