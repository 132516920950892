import {connect} from 'react-redux'
import {AppDispatch, RootState} from '../../common/redux/store'
import {changeModule} from '../redux/slice'
import ModuleTestSelection from '../components/ModuleTestSelection'
import Module from '../../common/model/Module'

const mapStateToProps = (state: RootState) => ({
  module: state.selection.module,
  moduleList: state.selection.moduleList,
})

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  changeModule(module: Module) {
    dispatch(changeModule(module))
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(ModuleTestSelection)
