import React from 'react'
import MCQRenderer from './QuestionRenderers/MCQRenderer'
import AssertionReasonRenderer from './QuestionRenderers/AssertionReasonRenderer'
import Question from '../../common/model/Question'
import QuestionInstance from '../../common/model/QuestionInstance'

interface Props {
  question: Question
  questionInstance: QuestionInstance
  selectAnswer: (answer: string) => void
}

export default function QuestionRenderer(props: Props) {
  if (props.question.typeId === 'MCQ' || 1) {
    return (
      <MCQRenderer
        question={props.question}
        questionInstance={props.questionInstance}
        selectAnswer={props.selectAnswer}
      />
    )
  } else if (props.question.typeId === 'AR') {
    return (
      <AssertionReasonRenderer
        question={props.question}
        questionInstance={props.questionInstance}
        selectAnswer={props.selectAnswer}
      />
    )
  } else {
    return <div />
  }
}
