import {connect} from 'react-redux'
import {AppDispatch, RootState} from '../../common/redux/store'
import {changeTestData, setInterval, reduceTime, nextQuestion, previousQuestion, selectAnswer} from '../redux/slice'
import {submitTest} from '../redux/thunks'
import TestScreen from '../components/TestScreen'

const mapStateToProps = (state: RootState) => ({
    testData: state.test.testData,
    questionNumber: state.test.questionNumber,
    time: state.test.time,
    interval: state.test.interval,
})

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    changeTestData: (testData: any) => {
        dispatch(changeTestData(testData))
    },
    setInterval: (interval: number) => {
        dispatch(setInterval(interval))
    },
    reduceTime: () => {
        dispatch(reduceTime())
    },
    nextQuestion: () => {
        dispatch(nextQuestion())
    },
    previousQuestion: () => {
        dispatch(previousQuestion())
    },
    submitTest: () => {
        dispatch(submitTest())
    },
    selectAnswer: (answer: string) => {
        dispatch(selectAnswer(answer))
    },
})

export default connect(mapStateToProps, mapDispatchToProps)(TestScreen)
