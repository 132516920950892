import Question from '../../model/Question'
import moduleList from "./moduleList";

const questionList: Question[] = [
  {
    id: '1',
    content: {
      question:
        'This is a sample question. Please select an answer to this question. Correct answer would be choice #1. What is the answer to this question?',
      choices: ['Choice #1', 'Choice #2', 'Choice #3', 'Choice #4'],
      imageChoice: false,
      supportImage: '',
    },
    module: moduleList[0],
    answer: 'Choice #1',
    typeId: 'MCQ',
  },
  {
    id: '1',
    content: {
      question:
        'This is a sample question. Please select an answer to this question. Correct answer would be choice #1. What is the answer to this question?',
      choices: ['Choice #1', 'Choice #2', 'Choice #3', 'Choice #4'],
      imageChoice: false,
      supportImage: '',
    },
    module: moduleList[0],
    answer: 'Choice #1',
    typeId: 'MCQ',
  },
  {
    id: '1',
    content: {
      question:
        'This is a sample question. Please select an answer to this question. Correct answer would be choice #1. What is the answer to this question?',
      choices: ['Choice #1', 'Choice #2', 'Choice #3', 'Choice #4'],
      imageChoice: false,
      supportImage: '',
    },
    module: moduleList[0],
    answer: 'Choice #1',
    typeId: 'MCQ',
  },
  {
    id: '1',
    content: {
      question:
        'This is a sample question. Please select an answer to this question. Correct answer would be choice #1. What is the answer to this question?',
      choices: ['Choice #1', 'Choice #2', 'Choice #3', 'Choice #4'],
      imageChoice: false,
      supportImage: '',
    },
    module: moduleList[0],
    answer: 'Choice #1',
    typeId: 'MCQ',
  },
  {
    id: '1',
    content: {
      question:
        'This is a sample question. Please select an answer to this question. Correct answer would be choice #1. What is the answer to this question?',
      choices: ['Choice #1', 'Choice #2', 'Choice #3', 'Choice #4'],
      imageChoice: false,
      supportImage: '',
    },
    module: moduleList[0],
    answer: 'Choice #1',
    typeId: 'MCQ',
  },
  {
    id: '1',
    content: {
      question:
        'This is a sample question. Please select an answer to this question. Correct answer would be choice #1. What is the answer to this question?',
      choices: ['Choice #1', 'Choice #2', 'Choice #3', 'Choice #4'],
      imageChoice: false,
      supportImage: '',
    },
    module: moduleList[0],
    answer: 'Choice #1',
    typeId: 'MCQ',
  },
  {
    id: '1',
    content: {
      question:
        'This is a sample question. Please select an answer to this question. Correct answer would be choice #1. What is the answer to this question?',
      choices: ['Choice #1', 'Choice #2', 'Choice #3', 'Choice #4'],
      imageChoice: false,
      supportImage: '',
    },
    module: moduleList[0],
    answer: 'Choice #1',
    typeId: 'MCQ',
  },
  {
    id: '1',
    content: {
      question:
        'This is a sample question. Please select an answer to this question. Correct answer would be choice #1. What is the answer to this question?',
      choices: ['Choice #1', 'Choice #2', 'Choice #3', 'Choice #4'],
      imageChoice: false,
      supportImage: '',
    },
    module: moduleList[0],
    answer: 'Choice #1',
    typeId: 'MCQ',
  },
  {
    id: '1',
    content: {
      question:
        'This is a sample question. Please select an answer to this question. Correct answer would be choice #1. What is the answer to this question?',
      choices: ['Choice #1', 'Choice #2', 'Choice #3', 'Choice #4'],
      imageChoice: false,
      supportImage: '',
    },
    module: moduleList[0],
    answer: 'Choice #1',
    typeId: 'MCQ',
  },
  {
    id: '1',
    content: {
      question:
        'This is a sample question. Please select an answer to this question. Correct answer would be choice #1. What is the answer to this question?',
      choices: ['Choice #1', 'Choice #2', 'Choice #3', 'Choice #4'],
      imageChoice: false,
      supportImage: '',
    },
    module: moduleList[0],
    answer: 'Choice #1',
    typeId: 'MCQ',
  },
  {
    id: '1',
    content: {
      question:
        'This is a sample question. Please select an answer to this question. Correct answer would be choice #1. What is the answer to this question?',
      choices: ['Choice #1', 'Choice #2', 'Choice #3', 'Choice #4'],
      imageChoice: false,
      supportImage: '',
    },
    module: moduleList[0],
    answer: 'Choice #1',
    typeId: 'MCQ',
  },
  {
    id: '1',
    content: {
      question:
        'This is a sample question. Please select an answer to this question. Correct answer would be choice #1. What is the answer to this question?',
      choices: ['Choice #1', 'Choice #2', 'Choice #3', 'Choice #4'],
      imageChoice: false,
      supportImage: '',
    },
    module: moduleList[0],
    answer: 'Choice #1',
    typeId: 'MCQ',
  },
  {
    id: '1',
    content: {
      question:
        'This is a sample question. Please select an answer to this question. Correct answer would be choice #1. What is the answer to this question?',
      choices: ['Choice #1', 'Choice #2', 'Choice #3', 'Choice #4'],
      imageChoice: false,
      supportImage: '',
    },
    module: moduleList[0],
    answer: 'Choice #1',
    typeId: 'MCQ',
  },
  {
    id: '1',
    content: {
      question:
        'This is a sample question. Please select an answer to this question. Correct answer would be choice #1. What is the answer to this question?',
      choices: ['Choice #1', 'Choice #2', 'Choice #3', 'Choice #4'],
      imageChoice: false,
      supportImage: '',
    },
    module: moduleList[0],
    answer: 'Choice #1',
    typeId: 'MCQ',
  },
  {
    id: '1',
    content: {
      question:
        'This is a sample question. Please select an answer to this question. Correct answer would be choice #1. What is the answer to this question?',
      choices: ['Choice #1', 'Choice #2', 'Choice #3', 'Choice #4'],
      imageChoice: false,
      supportImage: '',
    },
    module: moduleList[0],
    answer: 'Choice #1',
    typeId: 'MCQ',
  },
  {
    id: '1',
    content: {
      question:
        'This is a sample question. Please select an answer to this question. Correct answer would be choice #1. What is the answer to this question?',
      choices: ['Choice #1', 'Choice #2', 'Choice #3', 'Choice #4'],
      imageChoice: false,
      supportImage: '',
    },
    module: moduleList[0],
    answer: 'Choice #1',
    typeId: 'MCQ',
  },
  {
    id: '1',
    content: {
      question:
        'This is a sample question. Please select an answer to this question. Correct answer would be choice #1. What is the answer to this question?',
      choices: ['Choice #1', 'Choice #2', 'Choice #3', 'Choice #4'],
      imageChoice: false,
      supportImage: '',
    },
    module: moduleList[0],
    answer: 'Choice #1',
    typeId: 'MCQ',
  },
  {
    id: '1',
    content: {
      question:
        'This is a sample question. Please select an answer to this question. Correct answer would be choice #1. What is the answer to this question?',
      choices: ['Choice #1', 'Choice #2', 'Choice #3', 'Choice #4'],
      imageChoice: false,
      supportImage: '',
    },
    module: moduleList[0],
    answer: 'Choice #1',
    typeId: 'MCQ',
  },
  {
    id: '1',
    content: {
      question:
        'This is a sample question. Please select an answer to this question. Correct answer would be choice #1. What is the answer to this question?',
      choices: ['Choice #1', 'Choice #2', 'Choice #3', 'Choice #4'],
      imageChoice: false,
      supportImage: '',
    },
    module: moduleList[0],
    answer: 'Choice #1',
    typeId: 'MCQ',
  },
  {
    id: '1',
    content: {
      question:
        'This is a sample question. Please select an answer to this question. Correct answer would be choice #1. What is the answer to this question?',
      choices: ['Choice #1', 'Choice #2', 'Choice #3', 'Choice #4'],
      imageChoice: false,
      supportImage: '',
    },
    module: moduleList[0],
    answer: 'Choice #1',
    typeId: 'MCQ',
  },
  {
    id: '1',
    content: {
      question:
        'This is a sample question. Please select an answer to this question. Correct answer would be choice #1. What is the answer to this question?',
      choices: ['Choice #1', 'Choice #2', 'Choice #3', 'Choice #4'],
      imageChoice: false,
      supportImage: '',
    },
    module: moduleList[0],
    answer: 'Choice #1',
    typeId: 'MCQ',
  },
  {
    id: '1',
    content: {
      question:
        'This is a sample question. Please select an answer to this question. Correct answer would be choice #1. What is the answer to this question?',
      choices: ['Choice #1', 'Choice #2', 'Choice #3', 'Choice #4'],
      imageChoice: false,
      supportImage: '',
    },
    module: moduleList[1],
    answer: 'Choice #1',
    typeId: 'MCQ',
  },
  {
    id: '1',
    content: {
      question:
        'This is a sample question. Please select an answer to this question. Correct answer would be choice #1. What is the answer to this question?',
      choices: ['Choice #1', 'Choice #2', 'Choice #3', 'Choice #4'],
      imageChoice: false,
      supportImage: '',
    },
    module: moduleList[1],
    answer: 'Choice #1',
    typeId: 'MCQ',
  },
  {
    id: '1',
    content: {
      question:
        'This is a sample question. Please select an answer to this question. Correct answer would be choice #1. What is the answer to this question?',
      choices: ['Choice #1', 'Choice #2', 'Choice #3', 'Choice #4'],
      imageChoice: false,
      supportImage: '',
    },
    module: moduleList[1],
    answer: 'Choice #1',
    typeId: 'MCQ',
  },
  {
    id: '1',
    content: {
      question:
        'This is a sample question. Please select an answer to this question. Correct answer would be choice #1. What is the answer to this question?',
      choices: ['Choice #1', 'Choice #2', 'Choice #3', 'Choice #4'],
      imageChoice: false,
      supportImage: '',
    },
    module: moduleList[1],
    answer: 'Choice #1',
    typeId: 'MCQ',
  },
  {
    id: '1',
    content: {
      question:
        'This is a sample question. Please select an answer to this question. Correct answer would be choice #1. What is the answer to this question?',
      choices: ['Choice #1', 'Choice #2', 'Choice #3', 'Choice #4'],
      imageChoice: false,
      supportImage: '',
    },
    module: moduleList[1],
    answer: 'Choice #1',
    typeId: 'MCQ',
  },
  {
    id: '1',
    content: {
      question:
        'This is a sample question. Please select an answer to this question. Correct answer would be choice #1. What is the answer to this question?',
      choices: ['Choice #1', 'Choice #2', 'Choice #3', 'Choice #4'],
      imageChoice: false,
      supportImage: '',
    },
    module: moduleList[1],
    answer: 'Choice #1',
    typeId: 'MCQ',
  },
  {
    id: '1',
    content: {
      question:
        'This is a sample question. Please select an answer to this question. Correct answer would be choice #1. What is the answer to this question?',
      choices: ['Choice #1', 'Choice #2', 'Choice #3', 'Choice #4'],
      imageChoice: false,
      supportImage: '',
    },
    module: moduleList[1],
    answer: 'Choice #1',
    typeId: 'MCQ',
  },
  {
    id: '1',
    content: {
      question:
        'This is a sample question. Please select an answer to this question. Correct answer would be choice #1. What is the answer to this question?',
      choices: ['Choice #1', 'Choice #2', 'Choice #3', 'Choice #4'],
      imageChoice: false,
      supportImage: '',
    },
    module: moduleList[1],
    answer: 'Choice #1',
    typeId: 'MCQ',
  },
  {
    id: '1',
    content: {
      question:
        'This is a sample question. Please select an answer to this question. Correct answer would be choice #1. What is the answer to this question?',
      choices: ['Choice #1', 'Choice #2', 'Choice #3', 'Choice #4'],
      imageChoice: false,
      supportImage: '',
    },
    module: moduleList[1],
    answer: 'Choice #1',
    typeId: 'MCQ',
  },
  {
    id: '1',
    content: {
      question:
        'This is a sample question. Please select an answer to this question. Correct answer would be choice #1. What is the answer to this question?',
      choices: ['Choice #1', 'Choice #2', 'Choice #3', 'Choice #4'],
      imageChoice: false,
      supportImage: '',
    },
    module: moduleList[1],
    answer: 'Choice #1',
    typeId: 'MCQ',
  },
  {
    id: '1',
    content: {
      question:
        'This is a sample question. Please select an answer to this question. Correct answer would be choice #1. What is the answer to this question?',
      choices: ['Choice #1', 'Choice #2', 'Choice #3', 'Choice #4'],
      imageChoice: false,
      supportImage: '',
    },
    module: moduleList[1],
    answer: 'Choice #1',
    typeId: 'MCQ',
  },
  {
    id: '1',
    content: {
      question:
        'This is a sample question. Please select an answer to this question. Correct answer would be choice #1. What is the answer to this question?',
      choices: ['Choice #1', 'Choice #2', 'Choice #3', 'Choice #4'],
      imageChoice: false,
      supportImage: '',
    },
    module: moduleList[1],
    answer: 'Choice #1',
    typeId: 'MCQ',
  },
  {
    id: '1',
    content: {
      question:
        'This is a sample question. Please select an answer to this question. Correct answer would be choice #1. What is the answer to this question?',
      choices: ['Choice #1', 'Choice #2', 'Choice #3', 'Choice #4'],
      imageChoice: false,
      supportImage: '',
    },
    module: moduleList[1],
    answer: 'Choice #1',
    typeId: 'MCQ',
  },
  {
    id: '1',
    content: {
      question:
        'This is a sample question. Please select an answer to this question. Correct answer would be choice #1. What is the answer to this question?',
      choices: ['Choice #1', 'Choice #2', 'Choice #3', 'Choice #4'],
      imageChoice: false,
      supportImage: '',
    },
    module: moduleList[1],
    answer: 'Choice #1',
    typeId: 'MCQ',
  },
  {
    id: '1',
    content: {
      question:
        'This is a sample question. Please select an answer to this question. Correct answer would be choice #1. What is the answer to this question?',
      choices: ['Choice #1', 'Choice #2', 'Choice #3', 'Choice #4'],
      imageChoice: false,
      supportImage: '',
    },
    module: moduleList[1],
    answer: 'Choice #1',
    typeId: 'MCQ',
  },
  {
    id: '1',
    content: {
      question:
        'This is a sample question. Please select an answer to this question. Correct answer would be choice #1. What is the answer to this question?',
      choices: ['Choice #1', 'Choice #2', 'Choice #3', 'Choice #4'],
      imageChoice: false,
      supportImage: '',
    },
    module: moduleList[1],
    answer: 'Choice #1',
    typeId: 'MCQ',
  },
  {
    id: '1',
    content: {
      question:
        'This is a sample question. Please select an answer to this question. Correct answer would be choice #1. What is the answer to this question?',
      choices: ['Choice #1', 'Choice #2', 'Choice #3', 'Choice #4'],
      imageChoice: false,
      supportImage: '',
    },
    module: moduleList[1],
    answer: 'Choice #1',
    typeId: 'MCQ',
  },
  {
    id: '1',
    content: {
      question:
        'This is a sample question. Please select an answer to this question. Correct answer would be choice #1. What is the answer to this question?',
      choices: ['Choice #1', 'Choice #2', 'Choice #3', 'Choice #4'],
      imageChoice: false,
      supportImage: '',
    },
    module: moduleList[1],
    answer: 'Choice #1',
    typeId: 'MCQ',
  },
  {
    id: '1',
    content: {
      question:
        'This is a sample question. Please select an answer to this question. Correct answer would be choice #1. What is the answer to this question?',
      choices: ['Choice #1', 'Choice #2', 'Choice #3', 'Choice #4'],
      imageChoice: false,
      supportImage: '',
    },
    module: moduleList[1],
    answer: 'Choice #1',
    typeId: 'MCQ',
  },
  {
    id: '1',
    content: {
      question:
        'This is a sample question. Please select an answer to this question. Correct answer would be choice #1. What is the answer to this question?',
      choices: ['Choice #1', 'Choice #2', 'Choice #3', 'Choice #4'],
      imageChoice: false,
      supportImage: '',
    },
    module: moduleList[1],
    answer: 'Choice #1',
    typeId: 'MCQ',
  },
  {
    id: '1',
    content: {
      question:
        'This is a sample question. Please select an answer to this question. Correct answer would be choice #1. What is the answer to this question?',
      choices: ['Choice #1', 'Choice #2', 'Choice #3', 'Choice #4'],
      imageChoice: false,
      supportImage: '',
    },
    module: moduleList[2],
    answer: 'Choice #1',
    typeId: 'MCQ',
  },
  {
    id: '1',
    content: {
      question:
        'This is a sample question. Please select an answer to this question. Correct answer would be choice #1. What is the answer to this question?',
      choices: ['Choice #1', 'Choice #2', 'Choice #3', 'Choice #4'],
      imageChoice: false,
      supportImage: '',
    },
    module: moduleList[2],
    answer: 'Choice #1',
    typeId: 'MCQ',
  },
  {
    id: '1',
    content: {
      question:
        'This is a sample question. Please select an answer to this question. Correct answer would be choice #1. What is the answer to this question?',
      choices: ['Choice #1', 'Choice #2', 'Choice #3', 'Choice #4'],
      imageChoice: false,
      supportImage: '',
    },
    module: moduleList[2],
    answer: 'Choice #1',
    typeId: 'MCQ',
  },
  {
    id: '1',
    content: {
      question:
        'This is a sample question. Please select an answer to this question. Correct answer would be choice #1. What is the answer to this question?',
      choices: ['Choice #1', 'Choice #2', 'Choice #3', 'Choice #4'],
      imageChoice: false,
      supportImage: '',
    },
    module: moduleList[2],
    answer: 'Choice #1',
    typeId: 'MCQ',
  },
  {
    id: '1',
    content: {
      question:
        'This is a sample question. Please select an answer to this question. Correct answer would be choice #1. What is the answer to this question?',
      choices: ['Choice #1', 'Choice #2', 'Choice #3', 'Choice #4'],
      imageChoice: false,
      supportImage: '',
    },
    module: moduleList[2],
    answer: 'Choice #1',
    typeId: 'MCQ',
  },
  {
    id: '1',
    content: {
      question:
        'This is a sample question. Please select an answer to this question. Correct answer would be choice #1. What is the answer to this question?',
      choices: ['Choice #1', 'Choice #2', 'Choice #3', 'Choice #4'],
      imageChoice: false,
      supportImage: '',
    },
    module: moduleList[2],
    answer: 'Choice #1',
    typeId: 'MCQ',
  },
  {
    id: '1',
    content: {
      question:
        'This is a sample question. Please select an answer to this question. Correct answer would be choice #1. What is the answer to this question?',
      choices: ['Choice #1', 'Choice #2', 'Choice #3', 'Choice #4'],
      imageChoice: false,
      supportImage: '',
    },
    module: moduleList[2],
    answer: 'Choice #1',
    typeId: 'MCQ',
  },
  {
    id: '1',
    content: {
      question:
        'This is a sample question. Please select an answer to this question. Correct answer would be choice #1. What is the answer to this question?',
      choices: ['Choice #1', 'Choice #2', 'Choice #3', 'Choice #4'],
      imageChoice: false,
      supportImage: '',
    },
    module: moduleList[2],
    answer: 'Choice #1',
    typeId: 'MCQ',
  },
  {
    id: '1',
    content: {
      question:
        'This is a sample question. Please select an answer to this question. Correct answer would be choice #1. What is the answer to this question?',
      choices: ['Choice #1', 'Choice #2', 'Choice #3', 'Choice #4'],
      imageChoice: false,
      supportImage: '',
    },
    module: moduleList[2],
    answer: 'Choice #1',
    typeId: 'MCQ',
  },
  {
    id: '1',
    content: {
      question:
        'This is a sample question. Please select an answer to this question. Correct answer would be choice #1. What is the answer to this question?',
      choices: ['Choice #1', 'Choice #2', 'Choice #3', 'Choice #4'],
      imageChoice: false,
      supportImage: '',
    },
    module: moduleList[2],
    answer: 'Choice #1',
    typeId: 'MCQ',
  },
  {
    id: '1',
    content: {
      question:
        'This is a sample question. Please select an answer to this question. Correct answer would be choice #1. What is the answer to this question?',
      choices: ['Choice #1', 'Choice #2', 'Choice #3', 'Choice #4'],
      imageChoice: false,
      supportImage: '',
    },
    module: moduleList[2],
    answer: 'Choice #1',
    typeId: 'MCQ',
  },
  {
    id: '1',
    content: {
      question:
        'This is a sample question. Please select an answer to this question. Correct answer would be choice #1. What is the answer to this question?',
      choices: ['Choice #1', 'Choice #2', 'Choice #3', 'Choice #4'],
      imageChoice: false,
      supportImage: '',
    },
    module: moduleList[2],
    answer: 'Choice #1',
    typeId: 'MCQ',
  },
  {
    id: '1',
    content: {
      question:
        'This is a sample question. Please select an answer to this question. Correct answer would be choice #1. What is the answer to this question?',
      choices: ['Choice #1', 'Choice #2', 'Choice #3', 'Choice #4'],
      imageChoice: false,
      supportImage: '',
    },
    module: moduleList[2],
    answer: 'Choice #1',
    typeId: 'MCQ',
  },
  {
    id: '1',
    content: {
      question:
        'This is a sample question. Please select an answer to this question. Correct answer would be choice #1. What is the answer to this question?',
      choices: ['Choice #1', 'Choice #2', 'Choice #3', 'Choice #4'],
      imageChoice: false,
      supportImage: '',
    },
    module: moduleList[2],
    answer: 'Choice #1',
    typeId: 'MCQ',
  },
  {
    id: '1',
    content: {
      question:
        'This is a sample question. Please select an answer to this question. Correct answer would be choice #1. What is the answer to this question?',
      choices: ['Choice #1', 'Choice #2', 'Choice #3', 'Choice #4'],
      imageChoice: false,
      supportImage: '',
    },
    module: moduleList[2],
    answer: 'Choice #1',
    typeId: 'MCQ',
  },
  {
    id: '1',
    content: {
      question:
        'This is a sample question. Please select an answer to this question. Correct answer would be choice #1. What is the answer to this question?',
      choices: ['Choice #1', 'Choice #2', 'Choice #3', 'Choice #4'],
      imageChoice: false,
      supportImage: '',
    },
    module: moduleList[2],
    answer: 'Choice #1',
    typeId: 'MCQ',
  },
  {
    id: '1',
    content: {
      question:
        'This is a sample question. Please select an answer to this question. Correct answer would be choice #1. What is the answer to this question?',
      choices: ['Choice #1', 'Choice #2', 'Choice #3', 'Choice #4'],
      imageChoice: false,
      supportImage: '',
    },
    module: moduleList[2],
    answer: 'Choice #1',
    typeId: 'MCQ',
  },
  {
    id: '1',
    content: {
      question:
        'This is a sample question. Please select an answer to this question. Correct answer would be choice #1. What is the answer to this question?',
      choices: ['Choice #1', 'Choice #2', 'Choice #3', 'Choice #4'],
      imageChoice: false,
      supportImage: '',
    },
    module: moduleList[2],
    answer: 'Choice #1',
    typeId: 'MCQ',
  },
  {
    id: '1',
    content: {
      question:
        'This is a sample question. Please select an answer to this question. Correct answer would be choice #1. What is the answer to this question?',
      choices: ['Choice #1', 'Choice #2', 'Choice #3', 'Choice #4'],
      imageChoice: false,
      supportImage: '',
    },
    module: moduleList[2],
    answer: 'Choice #1',
    typeId: 'MCQ',
  },
  {
    id: '1',
    content: {
      question:
        'This is a sample question. Please select an answer to this question. Correct answer would be choice #1. What is the answer to this question?',
      choices: ['Choice #1', 'Choice #2', 'Choice #3', 'Choice #4'],
      imageChoice: false,
      supportImage: '',
    },
    module: moduleList[2],
    answer: 'Choice #1',
    typeId: 'MCQ',
  },
]

export default questionList
