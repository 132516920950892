import User from "../../common/model/User";
import Controller from "../../common/service/Controller";

class LeaderboardService {

    async getLeaderboardEntries(): Promise<User[]> {
        return Controller.fetch('/social/leaderboard', {})
    }
}

const leaderboardService: LeaderboardService = new LeaderboardService()

export default leaderboardService
