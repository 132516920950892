import React from 'react'
// @ts-ignore
import MathJax from 'react-mathjax2'
import {isDesktop} from 'react-device-detect'
import ThemeConstants from '../../../common/data/styles/ThemeConstants'
import Question from "../../../common/model/Question";

interface AnswerBlockProps {
    text: string
    answer: string
}

export default function AnswerBlock(props: AnswerBlockProps) {
    const styles = isDesktop ? stylesDesktop : stylesMobile

    return (
        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
            <div style={styles.answerHeading}>{props.text} Answer</div>
            <div style={styles.answer}>
                {Question.formatText(props.answer)}
            </div>
        </div>
    )
}

const stylesDesktop = {
    answerHeading: {
        fontSize: '1.1em',
        // color: '#5F6B7C',
        // fontWeight: 500,
        // marginRight: '0.5em',
        fontFamily: ThemeConstants.headingFont,
        width: '8em',
    },
    answer: {
        fontSize: '1.1em',
        borderRadius: 5,
        backgroundColor: ThemeConstants.primaryColorLight,
        fontFamily: ThemeConstants.headingFont,
        paddingTop: '0.6vw',
        paddingBottom: '0.6vw',
        paddingLeft: '1vw',
        paddingRight: '1vw',
        marginRight: '3vw',
        display: 'flex',
        alignItems: 'center' as 'center',
    },
}

const stylesMobile = {
    answerHeading: {
        fontSize: '2vh',
        // color: '#0ca950',
        marginRight: '2vh',
        fontFamily: "'Oswald', sans-serif",
    },
    answer: {
        fontSize: '2vh',
        borderRadius: '1vh',
        paddingTop: '1vh',
        paddingBottom: '1vh',
        paddingLeft: '2vh',
        paddingRight: '2vh',
        marginRight: '2vh',
        display: 'flex',
        alignItems: 'center' as 'center',
    },
}
