import Controller from "../../common/service/Controller";
import User from "../../common/model/User";

class ProfileService {

    async getProfile(): Promise<User> {
        return Controller.fetch('/user/get', {})
    }

    async saveProfile(user: User): Promise<void> {
        return Controller.fetch('/user/save', { user })
    }
}

const profileService: ProfileService = new ProfileService()

export default profileService
