import {AppDispatch, AppThunk, RootState} from "../../common/redux/store";
import leaderboardService from "../service/LeaderboardService";
import User from "../../common/model/User";
import {changeCompletionData, changeLeaderboardEntries, changeLogEntries} from "./slice";
import logService from "../service/LogService";
import LogEntry from "../../common/model/LogEntry";
import CompletionStatus from "../../common/model/CompletionStatus";
import completionStatusService from "../service/CompletionStatusService";
import NavigationService from "../../common/service/NavigationService";

export const getLeaderboardEntries = (): AppThunk => async (
    dispatch: AppDispatch,
    getState: () => RootState,
): Promise<void> => {
    const leaderboardEntries: User[] = await leaderboardService.getLeaderboardEntries()
    dispatch(changeLeaderboardEntries(leaderboardEntries))
}

export const getLogEntries = (): AppThunk => async (
    dispatch: AppDispatch,
    getState: () => RootState,
): Promise<void> => {
    const logEntries: LogEntry[] = await logService.getLogEntries()
    dispatch(changeLogEntries(logEntries))
}

export const getCompletionStatus = (): AppThunk => async (
    dispatch: AppDispatch,
    getState: () => RootState,
): Promise<void> => {
    const completionStatusList: CompletionStatus[] = await completionStatusService.getCompletionStatusData()
    dispatch(changeCompletionData(completionStatusList))
}

export const getDashboardData = (): AppThunk => async (
    dispatch: AppDispatch,
    getState: () => RootState,
): Promise<void> => {
    dispatch(getLeaderboardEntries())
    dispatch(getLogEntries())
    dispatch(getCompletionStatus())
    NavigationService.navigateToDashboard()
}
