import React from 'react'
import {TestType} from '../../common/model/SelectionFilter'
import FullTestSelection from './FullTestSelection'
import ModuleTestSelectionContainer from '../container/ModuleTestSelectionContainer'
import SetTestSelectionContainer from '../container/SetTestSelectionContainer'

interface TestBasedSelectionProps {
  testType: TestType | null
}

export default function TestBasedSelection(props: TestBasedSelectionProps) {
  switch (props.testType) {
    case 'FULL':
      return <FullTestSelection />
    case 'MODULE':
      return <ModuleTestSelectionContainer />
    case 'SET':
      return <SetTestSelectionContainer />
    default:
      return <div />
  }
}
