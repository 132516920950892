import {changeJwt, changeRefreshToken} from "../redux/slice";
import AuthenticationResponse from "../model/AuthenticationResponse";
import Controller from "../../../common/service/Controller";
import store from "../../../common/redux/store";
import User from "../../../common/model/User";

class AuthenticationService {

    private static validateUser(user: User): void {
        const mobileNumberRegex = /^[0-9]{10}$/
        if (!user.username) {
            throw Error('Provide a valid username')
        } else if (!mobileNumberRegex.test(user.mobileNumber)) {
            throw Error('Provide a valid 10 digit mobile number')
        } else if (user.name.length < 2) {
            throw Error("Please enter a valid name")
        } else if (!user.location) {
            throw Error("Please enter your location")
        }
    }

    private static validateRegistrationPassword(password: string): void {
        if (password.length < 4) {
            throw Error('Password should at least be 4 characters')
        }
    }

    async registerUser(user: User, password: string): Promise<AuthenticationResponse> {
        AuthenticationService.validateUser(user)
        AuthenticationService.validateRegistrationPassword(password)
        return Controller.fetch('/authentication/register', { user, password })
    }

    async loginUser(username: string, password: string): Promise<AuthenticationResponse> {
        return Controller.fetch('/authentication/login', { username, password })
    }

    async renewJWT(): Promise<void> {
        const authResponse: AuthenticationResponse = await Controller.fetch(
            '/authentication/renew',
            {
                refreshToken: store.getState().authentication.refreshToken,
            },
        )
        store.dispatch(changeJwt(authResponse.jwt));
        store.dispatch(changeRefreshToken(authResponse.refreshToken));
    }
}

const authenticationService: AuthenticationService = new AuthenticationService()

export default authenticationService