import User from "../../../common/model/User";

export interface InitialState {
    login: LoginState
    register: RegisterState
    jwt: string
    refreshToken: string
}

export interface RegisterState {
    user: User
    password: string
    confirmPassword: string
}

interface LoginState {
    username: string
    password: string
}

const initialState: InitialState = {
    login: {
        username: '',
        password: '',
    },
    jwt: '',
    refreshToken: '',
    register: {
        user: {
            username: '',
            name: '',
            mobileNumber: '',
            location: '',
            points: 0
        },
        password: '',
        confirmPassword: ''
    }
}

export default initialState
