import React from 'react'
import {TestType} from '../../common/model/SelectionFilter'
import TestBasedSelectionContainer from '../container/TestBasedSelectionContainer'
import {Button, MenuItem} from '@blueprintjs/core'
import {Select} from '@blueprintjs/select'
import ButtonStyle from '../../common/data/styles/ButtonStyle'
import ScreenWithHeader from '../../common/components/ScreenWithHeader'

interface SelectionProps {
    testType: TestType | null
    changeTestType: (testType: TestType) => void
    startTest: () => void
}

const TestTypeSelect = Select.ofType<{name: string; value: string}>()

export default function Selection(props: SelectionProps) {
    return (
        <ScreenWithHeader>
            <div style={styles.testType}>
                <div style={{margin: '1em'}}>
                    <TestTypeSelect
                        items={testTypes}
                        itemRenderer={(item) => (
                            <MenuItem
                                style={{...ButtonStyle, margin: '0'}}
                                text={item.name}
                                onClick={() => {
                                    props.changeTestType(item.value as TestType)
                                }}
                            />
                        )}
                        onItemSelect={(item) => {
                            props.changeTestType(item.value as TestType)
                        }}
                        filterable={false}>
                        <Button
                            text={
                                props.testType
                                    ? testTypes.filter((testType) => props.testType === testType.value)[0].name
                                    : 'Select Test Type'
                            }
                            style={styles.selectButton}
                            rightIcon={'chevron-down'}
                            minimal
                        />
                    </TestTypeSelect>
                </div>
                <Button
                    style={{...ButtonStyle, backgroundColor: 'white'}}
                    text={'Start Test'}
                    outlined
                    disabled={!props.testType}
                    rightIcon={'chevron-right'}
                    onClick={props.startTest}
                />
            </div>
            <TestBasedSelectionContainer />
        </ScreenWithHeader>
    )
}

const testTypes = [
    {name: 'Set Test', value: 'SET'},
    {name: 'Module Test', value: 'MODULE'},
    {name: 'Full Exam', value: 'FULL'},
]

const styles = {
    page: {
        backgroundColor: '#eee',
        height: '100%',
        display: 'flex',
        flexDirection: 'column' as 'column',
        alignItems: 'center',
    },
    testType: {
        display: 'flex',
        alignSelf: 'center',
        justifyContent: 'space-between',
        width: '100%',
    },
    testTypeSelect: {
        border: 'none',
        borderBottom: '2px solid white',
        fontSize: '1.2em',
        fontFamily: "'Poppins', sans-serif",
    },
    selectButton: {
        ...ButtonStyle,
        margin: '0',
        backgroundColor: 'white',
        borderRadius: '5px',
    },
}
