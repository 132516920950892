import React from 'react'
import {InputGroup} from '@blueprintjs/core'
import ThemeConstants from '../../common/data/styles/ThemeConstants'

interface InputProps {
    icon: string
    label: string
    value: string
    disabled?: boolean
    onChange?: (e: any) => void
}

export default function InputBlock(inputProps: InputProps) {
    return (
        <tr>
            <td style={styles.inputName}>{inputProps.label}</td>
            <td style={styles.inputValue}>
                <InputGroup
                    leftIcon={inputProps.icon}
                    style={styles.input}
                    value={inputProps.value}
                    onInput={(e) => {
                        if (inputProps.onChange) {
                            inputProps.onChange(e.currentTarget.value)
                        }
                    }}
                    disabled={inputProps.disabled}
                />
            </td>
        </tr>
    )
}

const styles = {
    inputName: {
        textAlign: 'right' as 'right',
        paddingRight: '2em',
        paddingTop: '1.5em',
        fontFamily: ThemeConstants.headingFont,
    },
    input: {width: '20em'},
    inputValue: {
        paddingTop: '1.5em',
        fontFamily: ThemeConstants.headingFont,
    },
}
