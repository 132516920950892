import React from 'react'
import User from "../../../common/model/User";
import {Button, Card, InputGroup, Intent} from "@blueprintjs/core";
import ThemeConstants from "../../../common/data/styles/ThemeConstants";
import AlternateOption from "../../common/components/AlternateOption";
import AuthenticationPage from "../../common/components/AuthenticationPage";
import {isDesktop} from "react-device-detect";

interface Props {
    user: User
    password: string
    confirmPassword: string
    changeUserName: (username: string) => void
    changeName: (username: string) => void
    changeLocation: (username: string) => void
    changeMobileNumber: (username: string) => void
    changePassword: (password: string) => void
    changeConfirmPassword: (password: string) => void
    registerUser: () => void
    navigateToLogin: () => void
}

interface InputProps {
    icon: string,
    label: string,
    property: string,
    onChange: (e: any) => void
}

export default function Register(props: Props) {
    function getRegisterBox() {
        return <div>
            {/*<div style={styles.cardContent}>*/}
            <h1 style={styles.heading}>Register</h1>
            <table>
                <tbody>
                    <tr>
                        <td style={styles.inputValue}>
                            <InputGroup
                                leftIcon={'person'}
                                placeholder={'Username'}
                                large
                                onChange={(e) => {
                                    props.changeUserName(e.target.value)
                                }}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td style={styles.inputValue}>
                            <InputGroup
                                leftIcon={'text-highlight'}
                                placeholder={'Name'}
                                large
                                onChange={(e) => {
                                    props.changeName(e.target.value)
                                }}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td style={styles.inputValue}>
                            <InputGroup
                                leftIcon={'map-marker'}
                                placeholder={'Location'}
                                large
                                onChange={(e) => {
                                    props.changeLocation(e.target.value)
                                }}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td style={styles.inputValue}>
                            <InputGroup
                                leftIcon={'phone'}
                                placeholder={'Phone Number'}
                                large
                                onChange={(e) => {
                                    props.changeMobileNumber(e.target.value)
                                }}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td style={styles.inputValue}>
                            <InputGroup
                                leftIcon={'key'}
                                placeholder={'Password'}
                                large
                                type={"password"}
                                onChange={(e) => {
                                    props.changePassword(e.target.value)
                                }}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td style={styles.inputValue}>
                            <InputGroup
                                leftIcon={'key'}
                                placeholder={'Confirm Password'}
                                large
                                type={"password"}
                                onChange={(e) => {
                                    props.changeConfirmPassword(e.target.value)
                                }}
                            />
                        </td>
                    </tr>
                </tbody>
            </table>
            <div style={styles.buttonBlock}>
                <Button
                    onClick={props.registerUser}
                    text={'Register'}
                    rightIcon={'floppy-disk'}
                    intent={Intent.SUCCESS}
                    large
                />
            </div>
            <AlternateOption
                label={'Already a member?'}
                action={'Login'}
                icon={'log-in'}
                onClick={props.navigateToLogin}
            />
            {/*</div>*/}
        </div>;
    }

    if (isDesktop) {
        return (
            <AuthenticationPage>
                <Card style={styles.card}>
                    {getRegisterBox()}
                </Card>
            </AuthenticationPage>
        )
    } else {
        return <div style={{
            justifyContent: 'center',
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            padding: '1em',
        }}>
            {getRegisterBox()}
        </div>
    }

    function InputBlock(inputProps: InputProps) {
        return (
            <tr>
                <td style={styles.inputValue}>
                    <InputGroup
                        leftIcon={inputProps.icon}
                        placeholder={inputProps.label}
                        large
                        onChange={(e) => {
                            inputProps.onChange(e.target.value)
                        }}
                    />
                </td>
            </tr>
        )
    }

    function changeProperty(onChange: (value: string) => void) {
        return (e: any) => {
            console.log(e)
            onChange(e.target.value)
        }
    }
}

const styles = {
    logo: {
        maxHeight: '10em',
        maxWidth: '50em',
        scale: '',
        margin: '2em',
    },
    quote: {
        marginTop: '-1em',
        marginBottom: '3em',
        color: '#5F6B7C',
    },
    quoteLine: {
        marginLeft: '4em',
        fontFamily: "'Poppins', sans-serif",
        fontSize: '2.5em',
    },
    leftPanel: {
        display: 'flex',
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column' as 'column',
        height: '100%',
    },
    rightPanel: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column' as 'column',
        alignItems: 'center',
        height: '100%',
        justifyContent: 'center',
    },
    inputName: {
        textAlign: 'right' as 'right',
        paddingRight: '2em',
        paddingTop: '1.5em',
        fontFamily: ThemeConstants.headingFont,
    },
    cardContent: {padding: '1em', paddingTop: '0'},
    card: {
        padding: '1em',
        margin: '4em',
        height: 'fit-content',
    },
    input: {width: '20em'},
    inputValue: {
        paddingTop: '1.5em',
        fontFamily: ThemeConstants.headingFont,
    },
    page: {
        height: '100%',
        width: '100%',
    },
    container: {
        display: 'flex',
        flex: 1,
        minHeight: '100%',
        justifyContent: 'center',
        alignItems: 'center',
    },
    heading: {
        fontWeight: '300',
        fontFamily: ThemeConstants.headingFont,
        marginTop: '1em',
        textAlign: 'center' as 'center',
    },
    buttonBlock: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: '2em',
    },
}