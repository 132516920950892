import {AppDispatch, RootState} from '../../common/redux/store'
import {
  changeName,
  changeLocation, resetUser,
} from '../redux/slice'
import {saveProfile} from '../redux/thunks'
import Profile from '../components/Profile'
import {connect} from 'react-redux'

const mapStateToProps = (state: RootState) => ({
  user: state.profile.modifiedUser
})

const mapDispatchToProps = (dispatch: AppDispatch) => ({

  changeName: (name: string) => {
    dispatch(changeName(name))
  },

  changeLocation: (location: string) => {
    dispatch(changeLocation(location))
  },

  saveProfile() {
    dispatch(saveProfile())
  },

  resetProfile() {
    dispatch(resetUser())
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(Profile)
