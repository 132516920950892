import React from 'react'
import {isDesktop} from 'react-device-detect'
import Test from '../../common/model/Test'
import ThemeConstants from '../../common/data/styles/ThemeConstants'
import {Alignment, Button, Icon, Navbar} from '@blueprintjs/core'

interface Props {
    submitTest: () => void
    testData: Test
    questionNumber: number
    setTestData: (testData: any) => void
    time: number
}

export default function Header(props: Props) {
    if (isDesktop) {
        return (
            <Navbar style={stylesDesktop.navBar}>
                <Navbar.Group align={Alignment.RIGHT} style={stylesDesktop.navBlock}>
                    <Button text={'Set Test'} minimal icon={'property'} />
                    <Icon icon={'chevron-right'} color={'#5F6B7C'} />
                    <Button text={'Loans'} minimal icon={'git-repo'} />
                    <Icon icon={'chevron-right'} color={'#5F6B7C'} />
                    <Button text={'Set 1'} minimal unselectable={'on'} />
                </Navbar.Group>
                <Navbar.Group align={Alignment.CENTER}>
                    <span style={stylesDesktop.timer}>{new Date(props.time * 1000).toISOString().substring(11, 8)}</span>
                </Navbar.Group>
            </Navbar>
        )
    } else {
        const styles = stylesMobile
        return (
            <div style={styles.container}>
                <div style={styles.topRow}>
                    <div style={styles.questionNumberContainer}>
                        <div style={styles.currentQuestionNumber}>{props.questionNumber + 1}</div>
                        <div style={styles.totalQuestions}>/ {props.testData.questionList.length}</div>
                    </div>
                    <Button
                        style={styles.button}
                        text={'Finish'}
                        large
                        onClick={props.submitTest}
                        intent={'danger'}
                        outlined
                    />
                </div>
            </div>
        )
    }
}

const stylesDesktop = {
    navBlock: {alignItems: 'center'},
    navBar: {
        display: 'flex',
        justifyContent: 'space-between',
        margin: '0px',
    },
    container: {
        display: 'flex',
        flexDirection: 'row' as 'row',
        justifyContent: 'space-between',
        alignItems: 'center' as 'center',
        backgroundColor: ThemeConstants.primaryColor,
    },
    body: {
        display: 'flex',
        flex: 1,
        flexDirection: 'row' as 'row',
        justifyContent: 'space-between',
    },
    questionNumberContainer: {
        display: 'flex',
        flexDirection: 'row' as 'row',
        alignItems: 'baseline',
        paddingLeft: '3vw',
        flex: 1,
    },
    currentQuestionNumber: {
        fontSize: '1vw',
        // color: 'white',
        fontFamily: ThemeConstants.headingFont,
        paddingRight: '1.2vw',
    },
    timer: {
        color: '#5F6B7C',
        fontSize: '1vw',
        display: 'flex',
        flex: 1,
        justifyContent: 'center',
        fontFamily: ThemeConstants.headingFont,
    },
    totalQuestions: {
        fontSize: '2.4vw',
        color: ThemeConstants.secondaryColor,
        fontFamily: ThemeConstants.headingFont,
    },
    topBgImageContainer: {display: 'flex', flex: 1, justifyContent: 'flex-end'},
    topBgImage: {height: '8vw'},
}

const stylesMobile = {
    container: {
        display: 'flex',
        flexDirection: 'column' as 'column',
        justifyContent: 'space-between',
    },
    topRow: {
        padding: '1vh',
        display: 'flex',
        alignItems: 'center' as 'center',
        flex: 1,
        flexDirection: 'row' as 'row',
        justifyContent: 'center',
        width: '100%',
        backgroundImage: 'url(./bgtop1-mobile.png)',
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'right',
    },
    questionNumberContainer: {
        display: 'flex',
        flexDirection: 'row' as 'row',
        alignItems: 'baseline',
        paddingLeft: '3vw',
        justifyContent: 'left' as 'center',
        flex: 1,
    },
    currentQuestionNumber: {
        // fontSize: '5vh',
        fontWeight: 700,
        // color: 'white',
        paddingRight: '1.2vw',
    },
    timer: {
        color: 'white',
        fontSize: '3vh',
        display: 'flex',
        flex: 1,
        justifyContent: 'center',
        fontFamily: ThemeConstants.headingFont,
        letterSpacing: 2,
    },
    totalQuestions: {
        // fontSize: '3vh',
        fontWeight: 700
    },
    bottomRow: {
        display: 'flex',
        flex: 1,
        justifyContent: 'right',
        width: '100%',
        paddingLeft: '2.5vh',
        paddingRight: '1.2vh',
        marginTop: '1vh',
        alignItems: 'right',
    },
    button: {
    },
    timerContainer: {marginRight: '3vh'},
    timerHeading: {color: 'white', fontSize: '2vh'},
}
