import QuestionInstance from "./QuestionInstance";
import questionList from "../data/prototype/questionList";

export default class Score {
    score: number = 0
    correctCount: number = 0
    incorrectCount: number = 0
    unansweredCount: number = 0

    static fromQuestionInstanceList(questionInstances: QuestionInstance[]): Score {
        const correctCount: number = Score.getCorrectCount(questionInstances)
        const unansweredCount: number = Score.getUnansweredCount(questionInstances)
        const incorrectCount: number = questionInstances.length - (correctCount + unansweredCount)
        return {
            score: correctCount,
            correctCount,
            incorrectCount,
            unansweredCount
        }
    }

    private static getUnansweredCount(questionInstances: QuestionInstance[]) {
        return questionInstances
            .map((questionInstance: QuestionInstance): number => (questionInstance.selectedAnswer === '' ? 1 : 0))
            .reduce((acc: number, curr: number) => acc + curr);
    }

    private static getCorrectCount(questionInstances: QuestionInstance[]) {
        return questionInstances
            .map((questionInstance: QuestionInstance, index): number => (
                questionInstance.selectedAnswer === questionList[index].answer ? 1 : 0
            ))
            .reduce((acc: number, curr: number) => acc + curr);
    }
}
