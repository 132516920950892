const ButtonStyle = {
  // backgroundColor: "#ffffff",
  // border: "none",
  fontSize: '1.3em',
  margin: '1em',
  fontFamily: "'Poppins', sans-serif",
  // fontWeight: 500,
  paddingRight: '1.5em',
  paddingLeft: '1.5em',
  paddingTop: '0.5em',
  paddingBottom: '0.5em',
  // color: "#09abee",
  // borderRadius: "0.2em",
}

export default ButtonStyle
