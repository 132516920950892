import React from 'react'
import './App.css'
import TestScreenContainer from './test/containers/TestScreenContainer'
import DashboardContainer from './dashboard/containers/DashboardContainer'
import SelectionContainer from './selection/container/SelectionContainer'
import LoginContainer from './authentication/login/containers/LoginContainer'
import ScoreCardContainer from './result/containers/ScoreCardContainer'
import AnswerKeyContainer from './result/containers/AnswerKeyContainer'
import ProfileContainer from './profile/containers/ProfileContainer'
import RegisterContainer from "./authentication/register/containers/RegisterContainer";

interface Props {
    location: string
}

function App(props: Props) {
    document.title = 'Brain Brush'
    document.body.style.overflow = 'auto'
    // @ts-ignore
    document.getElementsByTagName('META')[2].content = 'App for testing'

    return (props.location === "dashboard") ? <DashboardContainer />
        : (props.location === "login") ? <LoginContainer />
        : (props.location === "register") ? <RegisterContainer />
        : (props.location === "selection") ? <SelectionContainer />
        : (props.location === "test") ? <TestScreenContainer />
        : (props.location === "score-card") ? <ScoreCardContainer />
        : (props.location === "answer-key") ? <AnswerKeyContainer />
        : (props.location === "profile") ? <ProfileContainer />
        : <LoginContainer />
}

export default App
