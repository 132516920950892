import Controller from "../../common/service/Controller";
import LogEntry from "../../common/model/LogEntry";

class LogService {

    async getLogEntries(): Promise<LogEntry[]> {
        return Controller.fetch('/user/log', {})
    }
}

const logService: LogService = new LogService()

export default logService
