import * as React from 'react'
import ButtonStyle from '../../../common/data/styles/ButtonStyle'
import {Button} from '@blueprintjs/core'

interface Props {
  currentQuestionNumber: number
  previousQuestion: () => void
}

export default function PreviousButton(props: Props) {
  return (
    <Button
      style={ButtonStyle}
      onClick={props.previousQuestion}
      disabled={props.currentQuestionNumber === 0}
      text={'Back'}
      icon={'chevron-left'}
      outlined
    />
  )
}
