import React from 'react'
import ThemeConstants from '../../common/data/styles/ThemeConstants'
import ButtonStyle from '../../common/data/styles/ButtonStyle'
import {Button, Card, Icon, Intent} from '@blueprintjs/core'
import ScreenWithHeader from "../../common/components/ScreenWithHeader";

interface ScoreCardProps {
    score: number
    correctCount: number
    incorrectCount: number
    unansweredCount: number
    timeTaken: number
    navigateToAnswerKey: () => void
}

export default function ScoreCard(props: ScoreCardProps) {
    return (
        <ScreenWithHeader>
            <div style={{flex: 1, alignContent: 'center', flexDirection: 'column'}}>
            <h1 style={styles.heading}>Test Summary</h1>
            <Card style={{margin: '2vw'}}>
                <table>
                    <tr>
                        <td style={styles.textHeading}>
                            <Icon icon={'offline'} style={styles.icon} intent={Intent.PRIMARY} size={20} />
                            <div>Score</div>
                        </td>
                        <td style={styles.text}>{props.score}</td>
                    </tr>
                    <tr>
                        <td style={styles.textHeading}>
                            <Icon icon={'tick-circle'} style={styles.icon} intent={Intent.SUCCESS} size={20} />
                            <div>Correct Answers</div>
                        </td>
                        <td style={styles.text}>{props.correctCount}</td>
                    </tr>
                    <tr>
                        <td style={styles.textHeading}>
                            <Icon icon={'error'} style={styles.icon} intent={Intent.DANGER} size={20} />
                            <div>Wrong Answers</div>
                        </td>
                        <td style={styles.text}>{props.incorrectCount}</td>
                    </tr>
                    <tr>
                        <td style={styles.textHeading}>
                            <Icon icon={'ban-circle'} style={styles.icon} intent={Intent.WARNING} size={20} />
                            <div>Unanswered</div>
                        </td>
                        <td style={styles.text}>{props.unansweredCount}</td>
                    </tr>
                </table>
            </Card>
            <Button style={styles.button} text={'Answer Key'} outlined icon={'key'} onClick={props.navigateToAnswerKey} />
            </div>
        </ScreenWithHeader>
    )
}

const styles = {
    page: {
        display: 'flex',
        flexDirection: 'column' as 'column',
        flex: 1,
        alignItems: 'center',
    },
    textHeading: {
        display: 'flex',
        flexDirection: 'row' as 'row',
        alignItems: 'center' as 'center',
        padding: '1em',
        fontStyle: ThemeConstants.headingFont,
        fontSize: '1.3em',
        paddingRight: '2em',
    },
    text: {
        padding: '1em',
        fontStyle: ThemeConstants.headingFont,
        fontSize: '1.3em',
    },
    heading: {
        marginTop: '2em',
        marginBottom: '1em',
        fontStyle: ThemeConstants.headingFont,
        fontWeight: '400',
        marginLeft: '2vw',
    },
    icon: {marginRight: '0.7em'},
    button: {
        ...ButtonStyle,
        width: 'fit-content',
    },
}
