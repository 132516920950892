import * as React from 'react'
import ButtonStyle from '../../../common/data/styles/ButtonStyle'
import {Button} from '@blueprintjs/core'

interface Props {
    submitTest: () => void
}

export default function SubmitButton(props: Props) {
    return <Button
        style={ButtonStyle}
        onClick={props.submitTest}
        text={'Finish'}
        icon={'saved'}
        outlined
    />
}
