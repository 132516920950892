import React from 'react'
import logo from '../../common/logo.png'
import ThemeConstants from '../../../common/data/styles/ThemeConstants'

interface Props {
    children?: React.ReactNode;
}

export default function AuthenticationPage(props: Props) {
    return (
        <div style={styles.container}>
            <div style={styles.leftPanel}>
                {/*<img src={logo} alt={'logo'} style={styles.logo} />*/}
                {/*<div style={styles.quote}>*/}
                {/*    <div style={styles.quoteLine}>Grow to Serve, Serve to Grow</div>*/}
                {/*</div>*/}
            </div>
            <div style={styles.rightPanel}>
                {props.children}
            </div>
        </div>
    )
}

const styles = {
    logo: {
        maxHeight: '10em',
        maxWidth: '50em',
        scale: '',
        margin: '2em',
    },
    quote: {
        marginTop: '-1em',
        marginBottom: '3em',
        color: '#5F6B7C',
    },
    quoteLine: {
        marginLeft: '4em',
        fontFamily: "'Poppins', sans-serif",
        fontSize: '2.5em',
    },
    heading: {
        fontFamily: ThemeConstants.headingFont,
        fontWeight: 200,
        fontSize: '3.5em',
    },
    container: {
        height: '100%',
        display: 'flex',
        alignItems: 'center',
    },
    leftPanel: {
        display: 'flex',
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column' as 'column',
        height: '100%',
    },
    rightPanel: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column' as 'column',
        alignItems: 'center',
        height: '100%',
        justifyContent: 'center',
    },
    inputBlock: {
        display: 'flex',
        flexDirection: 'column' as 'column',
    },
    loginBox: {
        padding: '2em',
        display: 'flex',
        flexDirection: 'column' as 'column',
        alignItems: 'center',
        paddingRight: '5em',
        paddingLeft: '5em',
    },
}
