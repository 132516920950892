import React from 'react'
// @ts-ignore
import {isDesktop} from 'react-device-detect'
import {Button, Icon, Intent} from '@blueprintjs/core'
import ThemeConstants from '../data/styles/ThemeConstants'

interface HeaderNavBarItemProps {
    url: string
    icon: string
    text: string
    currentLocation: string
    onClick: () => void
}

export default function HeaderNavBarItem(props: HeaderNavBarItemProps) {
    const intent = props.currentLocation === props.url ? Intent.PRIMARY : Intent.NONE

    if (isDesktop) {
        return (
            <Button
                // @ts-ignore
                icon={props.icon}
                minimal
                text={props.text}
                style={styles.button}
                onClick={props.onClick}
                intent={intent}
            />
        )
    } else {
        return (
            <div
                style={{display: 'flex', flexDirection: 'column', alignItems: 'center', flex: 1}}
                onClick={props.onClick}
            >
                <Icon
                    icon={props.icon}
                    intent={intent}
                />
                <label>{props.text}</label>
            </div>
        )
    }
}

const styles = {
    button: {
        fontFamily: ThemeConstants.headingFont,
    },
}
