import {connect} from 'react-redux'
import {changeLoginPassword, changeLoginUsername} from '../../common/redux/slice'
import {AppDispatch, RootState} from '../../../common/redux/store'
import Login from '../components/Login'
import {loginUser} from "../../common/redux/thunks";
import NavigationService from "../../../common/service/NavigationService";

const mapStateToProps = (state: RootState) => ({
  username: state.authentication.login.username,
  password: state.authentication.login.password,
})

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  changeUsername: (username: string) => {
    dispatch(changeLoginUsername(username))
  },

  changePassword: (password: string) => {
    dispatch(changeLoginPassword(password))
  },

  attemptLogin: () => {
    dispatch(loginUser())
  },

  navigateToRegister: () => {
    NavigationService.navigateToRegister()
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(Login)
