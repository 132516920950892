import {configureStore, Action} from '@reduxjs/toolkit'
import {enableMapSet} from 'immer'
import {ThunkAction} from 'redux-thunk'
import rootReducer from './reducer'

enableMapSet()

const store = configureStore({
    reducer: rootReducer,
})

export type AppThunk = ThunkAction<void, RootState, unknown, Action<string>>
export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof rootReducer>
export default store
