import React from 'react'
import CompletionStatus from '../../../common/model/CompletionStatus'
import {Intent, ProgressBar} from '@blueprintjs/core'
import ThemeConstants from '../../../common/data/styles/ThemeConstants'

interface CompletionStatusViewProps {
    completionStatus: CompletionStatus
    intent: Intent
}

export default function CompletionStatusView(props: CompletionStatusViewProps) {
    return (
        <div style={styles.item}>
            <div style={styles.topPanel}>
                <div style={styles.leftPanel}>
                    <div style={styles.moduleName}>{props.completionStatus.name}</div>
                    <ProgressBar
                        stripes={false}
                        intent={props.intent}
                        value={props.completionStatus.completedSets / props.completionStatus.totalSets}
                    />
                    <div style={{marginTop: '0.5em', color: '#5F6B7C'}}>
                        Completed Sets: {props.completionStatus.completedSets} | Total
                        Sets: {props.completionStatus.totalSets}
                    </div>
                </div>
            </div>
            <div style={styles.moduleTests}>
                Module Tests Attended: {props.completionStatus.moduleTestCount}
            </div>
        </div>
    )
}

const styles = {
    item: {
        width: '100%',
        display: 'flex' as 'flex',
        flexDirection: 'column' as 'column',
        marginBottom: '1em',
        backgroundColor: '#fff',
        padding: '1em',
        borderRadius: '5px',
    },
    moduleTests: {
        marginTop: '0.5em',
        fontSize: '1.1em',
        fontWeight: 510,
        fontStyle:
        ThemeConstants.headingFont
    },
    moduleName: {
        marginBottom: '1em',
        fontSize: '1.2em',
        fontWeight: 600
    },
    leftPanel: {
        display: 'flex' as 'flex',
        flexDirection: 'column' as 'column',
        flex: 1,
        marginRight: '2em'
    },
    topPanel: {
        display: 'flex' as 'flex',
        flexDirection: 'row' as 'row'
    },
}
