import Test, {TestManipulator} from '../../common/model/Test'

interface InitialState {
  testData: Test
  questionNumber: number
  time: number
  interval: any
}

const initialState: InitialState = {
  testData: TestManipulator.getMockInstance(),
  questionNumber: 0,
  time: 1200,
  interval: null,
}

export default initialState
