import {createSlice, current, PayloadAction} from '@reduxjs/toolkit'
import initialState from './initialState'
import Test from '../../common/model/Test'
import Score from "../../common/model/Score";

const Slice = createSlice({
    name: 'Test',
    initialState,
    reducers: {
        reduceTime(state) {
            state.time--
        },
        setTime(state, action: PayloadAction<number>) {
            state.time = action.payload
        },
        changeTestData(state, action: PayloadAction<Test>) {
            state.testData = action.payload
            state.questionNumber = 0
        },
        valueQuestions(state, action: PayloadAction<void>) {
            for (let i = 0; i < state.testData.questionList.length; i++) {
                if (state.testData.questionList[i].answer === state.testData.questionInstanceList[i].selectedAnswer) {
                    state.testData.questionInstanceList[i] = {
                        ...state.testData.questionInstanceList[i], correct: true
                    }
                }
            }
            state.testData.score = Score.fromQuestionInstanceList(state.testData.questionInstanceList)
        },
        nextQuestion(state) {
            if (current(state).questionNumber < current(state).testData.questionList.length - 1) {
                state.questionNumber = state.questionNumber + 1
            }
        },
        previousQuestion(state) {
            if (state.questionNumber > 0) {
                state.questionNumber--
            }
        },
        setInterval(state, action: PayloadAction<any>) {
            state.interval = action.payload
        },
        selectAnswer(state, action: PayloadAction<string>) {
            state.testData.questionInstanceList[current(state).questionNumber].selectedAnswer = action.payload
        },
    },
})

export default Slice.reducer

export const {
    reduceTime,
    setTime,
    nextQuestion,
    previousQuestion,
    changeTestData,
    setInterval,
    selectAnswer,
    valueQuestions
} = Slice.actions
