import React from 'react'
import {H2, Icon, Intent} from '@blueprintjs/core'
import User from "../../../common/model/User";
import ThemeConstants from "../../../common/data/styles/ThemeConstants";

interface LeaderboardEntryProps {
    index: number
    leaderboardEntry: User
}

export default function LeaderboardEntry(props: LeaderboardEntryProps) {
    return (
        <tr>
            <div style={styles.leaderboardItem}>
                <div style={styles.identityBlock}>
                    <td style={styles.rank}>{props.index + 1}</td>
                    <td>
                        <div style={styles.name}>
                            {props.leaderboardEntry.name ? props.leaderboardEntry.name : props.leaderboardEntry.username}
                        </div>
                        <div style={styles.location}>
                            <Icon icon={'map-marker'} style={styles.icon} intent={getIntent(props.index)}/>
                            <div>{props.leaderboardEntry.location}</div>
                        </div>
                    </td>
                </div>
                <td style={styles.pointsBlock}>
                    <H2 style={styles.points}>{props.leaderboardEntry.points}</H2>
                    <Icon icon={'offline'} style={styles.pointsIcon} intent={getIntent(props.index)}/>
                </td>
            </div>
        </tr>
    );
}

const getIntent = (value: number): Intent => {
    switch (value % 3) {
        case 0:
            return Intent.SUCCESS
        case 1:
            return Intent.PRIMARY
        case 2:
            return Intent.DANGER
        default:
            return Intent.NONE
    }
}

const styles = {
    icon: {marginRight: '0.5em'},
    points: {
        fontSize: '1.2em',
        marginBottom: '0',
    },
    identityBlock: {display: 'flex'},
    pointsIcon: {marginLeft: '0.2em'},
    name: {
        fontStyle: ThemeConstants.headingFont,
        fontSize: '1.2em',
    },
    leaderboardItem: {
        paddingTop: '0.5em',
        paddingBottom: '0.5em',
        display: 'flex',
        flexDirection: 'row' as 'row',
        justifyContent: 'space-between',
    },
    rank: {
        fontStyle: ThemeConstants.headingFont,
        fontSize: '1.2em',
        marginRight: '1em',
    },
    location: {
        fontStyle: ThemeConstants.headingFont,
        color: '#5F6B7C',
        display: 'flex',
        alignItems: 'center',
        marginTop: '0.4em',
    },
    pointsBlock: {
        alignSelf: 'center',
        display: 'flex',
        flexDirection: 'row' as 'row',
        alignItems: 'center',
    },
}
